import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Padoru from 'util/padoru';
import FormV2Mobile from 'components/Form/FormV2Mobile';
import ToolbarMobile from 'components/ToolBar/ToolBarV2Mobile';
import WorkAreaMobile from 'components/WorkArea/WorkAreaMobile';
import type {
  ChangeColorFunctionV2,
  ChangeGradiantFunctionV2,
  ChangePartFunctionV2,
} from 'interfaces/app';
import type { KeyPart } from 'interfaces/padoru';

interface Props {
  onChangeName: (n: string) => void;
  onChangeSkin: (color: string) => void;
  onChangePart: ChangePartFunctionV2;
  onChangeColor: ChangeColorFunctionV2;
  onChangeGradiant: ChangeGradiantFunctionV2;
  onRestorePadoru: (p: Padoru) => void;
  isFirstVisit: boolean;
  openTuto: boolean;
  openTutorial: (o: boolean) => void;
  padoru: Padoru;
  padoruName: string;
}

export default function Maker2Mobile(props: Props) {
  const {
    onChangeName,
    onChangePart,
    onChangeColor,
    onChangeSkin,
    onChangeGradiant,
    onRestorePadoru,
    openTutorial,
    padoru,
    padoruName,
  } = props;

  const [partTab, setPartTab] = useState<KeyPart>('hair_middle');

  return (
    <React.Fragment>
      <Container
        id="maker_2_main_preview"
        component="section"
        maxWidth="sm"
        sx={{ overflow: 'auto', flex: 1, height: 'auto', background: 'white', p: 0 }}
      >
        <ToolbarMobile
          onChangeName={onChangeName}
          onNewPadoru={onRestorePadoru}
          onRestorePadoru={onRestorePadoru}
          openTutorial={openTutorial}
          padoruName={padoruName}
        />

        <WorkAreaMobile
          onChangeColor={onChangeColor}
          onChangeGradiant={onChangeGradiant}
          padoru={padoru}
          padoruName={padoruName}
          partTab={partTab}
          v="2"
        />
      </Container>

      <Container
        id="maker_2_main_pieces"
        component="section"
        maxWidth="sm"
        sx={{ overflow: 'auto', height: 'auto', background: 'white', p: 0 }}
      >
        <FormV2Mobile
          onChangeSkin={onChangeSkin}
          handlerPartTab={setPartTab}
          onChangePart={onChangePart}
          padoru={padoru}
          partTab={partTab}
        />
      </Container>
    </React.Fragment>
  );
}
