import { useCallback, useEffect, useState } from 'react';
import ErrorIcon from '@mui/icons-material/CancelRounded';
import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import CopyIcon from '@mui/icons-material/ContentCopyRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import Zoom from '@mui/material/Zoom';
import { MuiBasicColor } from 'interfaces/app';

interface Props {
  value?: string;
  onCopy?: (s: string) => void;
  onError?: (s: Error) => void;
  onChange?: (s: string) => void;
  variant?: TextFieldProps['variant'];
  icon?: JSX.Element;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

export default function CopyClipboardStyled(props: Readonly<Props>) {
  const {
    onCopy,
    onError,
    onChange,
    label,
    variant = 'filled',
    icon = <CopyIcon />,
    value: incommingValue = '',
    disabled = false,
    readOnly = false,
  } = props;
  const [value, setValue] = useState<string>('');
  const [status, setStatus] = useState<MuiBasicColor>('inherit');

  useEffect(() => {
    if (incommingValue) {
      setValue(incommingValue);
    }
  }, [incommingValue]);

  const getIcon = (c: MuiBasicColor, l = false) => {
    switch (c) {
      case 'success':
        return <CheckIcon color="success" />;
      case 'error':
        return <ErrorIcon color="error" />;
      default:
        return icon;
    }
  };

  const defaultStatus = () => {
    setStatus('inherit');
  };

  const canCopy = 'clipboard' in navigator || typeof document.execCommand === 'function';
  const handlerCopy = useCallback(async () => {
    let s: MuiBasicColor;
    try {
      if (!value) {
        throw new Error('Empty!');
      }
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(value);
      } else {
        document.execCommand?.('copy', true, value);
      }
      s = 'success';
      onCopy?.(value);
    } catch (err: any) {
      s = 'error';
      onError?.(err);
    } finally {
      setStatus(s);
      setTimeout(defaultStatus, 1500);
    }
  }, [value]);

  return (
    <Box>
      <TextField
        value={value}
        label={label}
        variant={variant}
        disabled={disabled}
        size="small"
        fullWidth
        onChange={e => {
          setValue(e.target.value);
          onChange?.(e.target.value);
        }}
        InputProps={{
          endAdornment: canCopy && (
            <IconButton onClick={handlerCopy} disabled={disabled}>
              <Box position="relative">
                <Box sx={{ opacity: Number(status === 'inherit') }}>{icon}</Box>
                <Box position="absolute" top={0} left={0}>
                  <Zoom in={status !== 'inherit'}>{getIcon(status)}</Zoom>
                </Box>
              </Box>
            </IconButton>
          ),
          readOnly: readOnly,
        }}
      />
    </Box>
  );
}
