import PadoruThumbnailAsset from 'assets/imgs/Padoru_Oficial.png';
import type { TFunction } from 'i18next';
import type { ISchema } from 'interfaces/app';
import type { Part, PartMap } from 'interfaces/padoru';
import type { KeyPart } from 'interfaces/padoru';

export enum InxPart {
  bg,
  wings,
  ponytail,
  hair_back,
  body,
  neck,
  head,
  hair_middle,
  ears,
  blush,
  eyes_pupil,
  eyes_iris,
  mouth,
  head_acc,
  mask,
  glasses,
  hair_front,
  hat,
  bag,
}

export enum Colors {
  LINE = '#000000',
  BLACK = '#000000',
  SHADOW = '#000000',
  WHITE = '#FFFFFF',
  CREAM = '#f9f6e7',
  LEAF = '#909249',
  YELLOW = '#ffd800',
  TURQUESA = '#35b4e2',
  RED = '#e53935',
  BLUE = '#3f51b5',
  PINK = '#ff93f7',
  TONGE = '#ec839a',
  VIOLET = '#d659f5',
  OC_HAIR = '#f9f964',
  OC_EYES = '#3eb12c',
}

export const AfectedSkinParts: KeyPart[] = ['head', 'ears'];
export const SkinTones: string[] = [
  '#ffe7d1',
  '#e6bc98',
  '#d4aa78',
  '#a16e4b',
  '#3b2219',
];

export const SchemaForm = (t: TFunction): ISchema => {
  return [
    {
      sectionName: t('schema.sections.hair'),
      sectionKey: 'hair',
      defaultColors: [Colors.OC_HAIR, Colors.OC_HAIR, Colors.BLUE],
      parts: [
        {
          name: t('schema.parts.hair_middle'),
          key: 'hair_middle',
          defaultUUID: '4rgbhyr',
        },
        {
          name: t('schema.parts.hair_front'),
          key: 'hair_front',
          defaultUUID: 'yrh4i62',
        },
        {
          name: t('schema.parts.head_acc'),
          key: 'head_acc',
          defaultUUID: 'none',
        },
      ],
    },
    {
      sectionName: t('schema.sections.hair_acc'),
      sectionKey: 'hair_acc',
      defaultColors: [Colors.OC_HAIR, Colors.TONGE, Colors.RED],
      parts: [
        {
          name: t('schema.parts.hair_back'),
          key: 'hair_back',
          defaultUUID: 'none',
        },
        {
          name: t('schema.parts.ponytail'),
          key: 'ponytail',
          defaultUUID: '55ihw0g',
        },
      ],
    },
    {
      sectionName: t('schema.sections.head'),
      sectionKey: 'head',
      defaultColors: [Colors.RED, Colors.WHITE],
      parts: [
        {
          name: t('schema.parts.hat'),
          key: 'hat',
          defaultUUID: 'none',
        },
      ],
    },
    {
      sectionName: t('schema.sections.face'),
      sectionKey: 'face',
      defaultColors: [Colors.OC_EYES, Colors.VIOLET],
      parts: [
        {
          name: t('schema.parts.eyes_pupil'),
          key: 'eyes_pupil',
          defaultUUID: 'jfklo45',
        },
        /* // FUTHURE
        {
          name: t('schema.parts.eyes_iris'),
          key: 'eyes_iris',
           defaultUUID: '',
        },
        */
        {
          name: t('schema.parts.blush'),
          key: 'blush',
          defaultUUID: 'none',
        },
        {
          name: t('schema.parts.mouth'),
          key: 'mouth',
          defaultUUID: 'rj4dsw',
        },
        {
          name: t('schema.parts.ears'),
          key: 'ears',
          defaultUUID: 'none',
        },
      ],
    },
    {
      sectionName: t('schema.sections.face_acc'),
      sectionKey: 'face_acc',
      defaultColors: [Colors.PINK, Colors.WHITE],
      parts: [
        {
          name: t('schema.parts.glasses'),
          key: 'glasses',
          defaultUUID: 'none',
        },
        {
          name: t('schema.parts.mask'),
          key: 'mask',
          defaultUUID: 'none',
        },
      ],
    },
    {
      sectionName: t('schema.sections.body'),
      sectionKey: 'body',
      defaultColors: [Colors.RED, Colors.WHITE, Colors.PINK],
      parts: [
        {
          name: t('schema.parts.body'),
          key: 'body',
          defaultUUID: 'cw0qahu',
        },
        {
          name: t('schema.parts.neck'),
          key: 'neck',
          defaultUUID: 'none',
        },
      ],
    },
    {
      sectionName: t('schema.sections.bag'),
      sectionKey: 'acc',
      defaultColors: [Colors.CREAM, Colors.TURQUESA, Colors.RED],
      parts: [
        {
          name: t('schema.parts.bag'),
          key: 'bag',
          defaultUUID: '29v9gf8',
        },
        {
          name: t('schema.parts.wings'),
          key: 'wings',
          defaultUUID: 'none',
        },
        /* // FUTHURE
        {
          name: t('schema.parts.bg'),
          key: 'bg',
          defaultUUID: '',
        },
        */
      ],
    },
  ];
};

const partsObjectsArr: Part[] = [];
SchemaForm(() => '').forEach(sch => [
  sch.parts.forEach(p => {
    partsObjectsArr.push({
      key: p.key,
      colors: sch.defaultColors,
      uuid: p.defaultUUID,
    });
  }),
]);
partsObjectsArr.push(
  {
    key: 'head',
    colors: [SkinTones[0]],
    uuid: 'standard',
  },
  {
    key: 'ears',
    colors: [SkinTones[0]],
    uuid: 'none',
  },
);

export const PadoruThumbnail = PadoruThumbnailAsset;
export const DefaultOCParts = new Map([...partsObjectsArr].map(p => [p.key, p]));

const pelonUidParts: Partial<{ [k in KeyPart]: string }> = {
  //bg: 'none', // FUTHURE
  wings: 'none',
  ponytail: 'none',
  hair_back: 'none',
  neck: 'none',
  hair_middle: 'none',
  eyes_pupil: 'jfklo45',
  //eyes_iris: 'none',
  blush: 'none',
  mouth: 'if37o77',
  head_acc: 'none',
  glasses: 'none',
  hair_front: 'none',
  hat: 'none',
  bag: 'none',
};
export const ZeroParts: PartMap = new Map(
  [...partsObjectsArr]
    .map(p => ({
      ...p,
      ...(pelonUidParts[p.key] && { uuid: pelonUidParts[p.key] }),
    }))
    .map(p => [p.key, p]),
);

export const marcaDeAguaProps = {
  height: 25,
  width: 165,
  xInit: 191,
  yInit: 330,
};

export const DefaultColorsV2: Map<KeyPart, string[]> = new Map([
  ['hair_middle', [Colors.OC_HAIR, Colors.OC_HAIR]],
  ['hair_front', [Colors.OC_HAIR, Colors.OC_HAIR]],
  ['head_acc', [Colors.OC_HAIR, Colors.OC_HAIR]],

  ['hair_back', [Colors.OC_HAIR, Colors.TONGE]],
  ['ponytail', [Colors.OC_HAIR, Colors.TONGE, Colors.RED]],

  ['hat', [Colors.RED, Colors.WHITE]],

  ['eyes_pupil', [Colors.OC_EYES]],
  ['blush', []],
  ['mouth', [Colors.VIOLET]],
  ['ears', []],

  ['glasses', [Colors.PINK]],
  ['mask', [Colors.WHITE]],

  ['body', [Colors.RED, Colors.WHITE]],
  ['neck', [Colors.PINK]],

  ['bag', [Colors.CREAM]],
  ['wings', [Colors.TURQUESA, Colors.RED]],
]);
