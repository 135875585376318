import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { colors, useMediaQuery } from '@mui/material';
import logoIcon from 'assets/icons/icon_hat_corona.png';
import Language from 'components/Language';

interface Props {
  transparent?: boolean;
}

const ResponsiveAppBar = (props: Props) => {
  const isMd = useMediaQuery('(max-width:900px)');
  const isXs = useMediaQuery('(max-width:320px)');
  const { transparent } = props;

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: transparent ? 'transparent' : colors.indigo[900],
        color: 'white',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            py: 1.3,
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Typography
            id="logo_name_link"
            display={transparent ? 'none' : 'flex'}
            variant={isXs ? 'h5' : isMd ? 'h4' : 'h2'}
            lineHeight="unset"
            component="a"
            fontFamily="'Pacifico', cursive"
            color={colors.lightGreen[700]}
            href="/"
            sx={{ textDecoration: 'none' }}
          >
            {!isMd && <Box component="img" mr={1.4} height="1.7em" src={logoIcon} />}
            <Box component="span">Padoru Maker</Box>
          </Typography>
          <Box ml="auto">
            <Language isMd={isMd} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
