import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from 'components/Footer';
import Header from 'components/Header';
import SimpleModal from 'components/Modals';
import ReloadModal from 'components/Modals/ReloadModal';
import Home from 'pages/Home';
import Main from 'pages/Main';
import MakerV2 from 'pages/Maker2';
import NotFound from 'pages/NotFound';
import Thanks from 'pages/Thanks';
import css from './app.styles';
import { DONATE_THANKS, MAKER, MAKER_V2, ROOT } from './paths';

const pathApplySizeLoad = [MAKER, MAKER_V2];
const getOrientation = () => window.screen.orientation.type;
const featureReloadedON = String(process.env.REACT_APP_FEATURE_RELOADED) === 'true';
const disableMenu = String(process.env.REACT_APP_FEATURE_BLOCK_MENU) === 'true';

export default function App() {
  const pathname = window.location.pathname;
  const [orientation, setOrientation] = useState<string>(getOrientation());
  const [initDone, setInit] = useState<boolean>(false);
  const [openModalResize, setOpenModalRezise] = useState<boolean>(false);
  const updateOrientation = () => setOrientation(getOrientation());

  useEffect(() => {
    setInit(true);
    window.addEventListener('orientationchange', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, []);

  useEffect(() => {
    if (featureReloadedON && initDone && pathApplySizeLoad.includes(pathname)) {
      setOpenModalRezise(true);
    }
  }, [orientation]);

  if (disableMenu) {
    document.oncontextmenu = function () {
      return false;
    };
  }

  return (
    <div>
      <SimpleModal
        open={featureReloadedON && openModalResize}
        onClose={() => setOpenModalRezise(false)}
        btnClose
        sx={{ mt: 2 }}
      >
        <ReloadModal onClose={() => setOpenModalRezise(false)} />
      </SimpleModal>

      <Box id="main_box_wrapper">
        <BrowserRouter>
          <Routes>
            <Route path={ROOT} element={<Page page={<Home />} headerTransparent />} />
            <Route path={MAKER} element={<Page page={<Main />} />} />
            <Route path={DONATE_THANKS} element={<Page page={<Thanks />} />} />
            <Route path={MAKER_V2} element={<MakerV2 />} />
            <Route path="*" element={<Page page={<NotFound />} />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </div>
  );
}

interface Props {
  page: JSX.Element;
  headerTransparent?: boolean;
}

function Page(props: Props) {
  const { page, headerTransparent } = props;
  const isSm = useMediaQuery('(max-width:600px)');

  return (
    <Box id="main_box" sx={css.mainBox}>
      <Stack
        id="main_stack"
        direction="column"
        justifyContent="space-between"
        height="max-content"
      >
        <Header transparent={headerTransparent} />
        <Container
          id="main_container"
          component="main"
          maxWidth="lg"
          sx={css.mainContainer}
        >
          <Box id="wraper_contenido" position="relative" height="max-content">
            {!isSm && <Box id="blur_bg" sx={css.mainBlur} />}
            <Container maxWidth="lg" sx={css.content}>
              {page}
            </Container>
          </Box>
        </Container>
        <Footer />
      </Stack>
    </Box>
  );
}
