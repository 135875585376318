export interface Lang {
  name: string;
  label: string;
}

export const LANGS: Lang[] = [
  {
    name: 'Español',
    label: 'es',
  },
  {
    name: 'English',
    label: 'en',
  },
  {
    name: 'Italiano',
    label: 'it',
  },
  {
    name: 'Português',
    label: 'pt',
  },
  {
    name: 'Français',
    label: 'fr',
  },
  // ctp: Deutsch, 中文
];
