import { memo, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import NoneTwoColorsIcon from '@mui/icons-material/InvertColorsOffRounded';
import PaletteIcon from '@mui/icons-material/PaletteRounded';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { contrastColor, normalizeColor } from 'util/helpers';
import CopyInput from 'components/Inputs/CopyInput';
import IconText from 'components/Labels/IconText';

interface Props {
  label: string;
  onChangeColor: (color: string) => void;
  color: string;
  disabled: boolean;
  floating?: boolean;
  warningMode?: boolean;
}

function BtnColorV2(props: Readonly<Props>) {
  const { t } = useTranslation();
  const {
    label,
    onChangeColor,
    color,
    disabled,
    floating = false,
    warningMode = false,
  } = props;

  const [anchorElColor, setAnchorElColor] = useState<HTMLButtonElement | null>(null);

  const handleClickColor = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElColor(event.currentTarget);
  };

  const handleCloseColor = () => {
    setAnchorElColor(null);
  };

  return (
    <Box>
      <IconButton
        sx={{ bgcolor: color, p: 1, color: contrastColor(color), borderRadius: 1 }}
        onClick={handleClickColor}
        disabled={disabled}
      >
        <PaletteIcon fontSize={floating ? 'medium' : 'large'} />
      </IconButton>

      <Popover
        id={`popover_color_ind_${label}`}
        open={!!anchorElColor}
        anchorEl={anchorElColor}
        anchorReference={floating ? 'anchorPosition' : 'anchorEl'}
        anchorPosition={{
          top: document.documentElement.clientHeight / 2,
          left: document.documentElement.clientWidth / 10,
        }}
        onClose={handleCloseColor}
        transitionDuration={100}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ sx: { backgroundColor: grey[100], ml: 1.5 } }}
      >
        <Stack direction="column" spacing={2} p={2} id={`stack_color_ind_${label}`}>
          {warningMode && (
            <Alert severity="warning" variant="outlined">
              <IconText
                icon={<NoneTwoColorsIcon />}
                text={t('components.tools.tier.two_colors')}
                variant="caption"
              />
            </Alert>
          )}
          <CopyInput
            label={label}
            value={color}
            variant="outlined"
            onError={console.error}
            onChange={c => onChangeColor(normalizeColor(c))}
            disabled={disabled}
          />
          <Box width={1}>
            {!disabled && (
              <HexColorPicker
                color={color}
                onChange={onChangeColor}
                style={{ width: '100%' }}
              />
            )}
          </Box>
        </Stack>
      </Popover>
    </Box>
  );
}

const BtnColorV2Memo = memo(BtnColorV2, (prev, next) => {
  const sameColor = prev.color === next.color;
  const shouldRender = !sameColor;
  return !shouldRender;
});

export default BtnColorV2Memo;

/**
 * return true = no render
 * return false = render
 */
