enum Events {
  NEW_PADORU = 'new_padoru',
  OPEN_TUTORIAL = 'open_tutorial',
}

type EventName = `${Events}`;
type CallbackFunction = (data: any) => any;
interface EventEmitter {
  _events: {
    [e in Events]: CallbackFunction[];
  };
  emmit: (event: EventName, data: any) => void;
  subscribe: (event: EventName, callback: CallbackFunction) => void;
  unsubscribe: (event: EventName) => void;
}

const eventManager: EventEmitter = {
  _events: {} as any,
  emmit(event: EventName, data: any) {
    if (!this._events[event]) return;
    this._events[event].forEach(callback => callback(data));
  },
  subscribe(event: EventName, callback: CallbackFunction) {
    if (!this._events[event]) this._events[event] = [];
    this._events[event].push(callback);
  },
  unsubscribe(event: EventName) {
    if (!this._events[event]) return;
    delete (this._events as any)[event];
  },
};

export default eventManager;
