import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/CheckRounded';
import PaletteIcon from '@mui/icons-material/PaletteRounded';
import type { SxProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { pink } from '@mui/material/colors';
import { SkinTones } from 'CONSTANTS';
import { contrastColor } from 'util/helpers';
import ColorPicker from 'components/ColorPicker';

interface Props {
  skin: string;
  onChangeSkin: (color: string) => void;
  sx?: SxProps;
  isMobile?: boolean;
}
export default function SelectorSkinCard(props: Props) {
  const { t } = useTranslation();
  const { skin: padoruSkin, onChangeSkin, isMobile, sx = {} } = props;

  const [selectColor, setSelectColor] = useState<string>(pink.A100);
  const [skinColor, setSkinColor] = useState<string>(padoruSkin);

  useEffect(() => {
    setSkinColor(padoruSkin);
  }, [padoruSkin]);

  const handleChangeSkinColor = (
    _event: React.MouseEvent<HTMLElement>,
    color: string,
  ) => {
    if (SkinTones.includes(color)) {
      setSkinColor(color);
    }
  };

  return (
    <Card sx={sx} id="selector_card_skin">
      <CardContent>
        <Typography variant={isMobile ? 'body1' : 'h5'}>
          {t('components.work_area.skin')}
        </Typography>
        <Box display="flex" justifyContent="center">
          <ToggleButtonGroup
            value={skinColor}
            exclusive
            onChange={handleChangeSkinColor}
            sx={{ m: 1, overflow: 'auto' }}
          >
            {SkinTones.map((skin: string, key: number) => (
              <ToggleButton
                key={key}
                value={skin}
                selected={skinColor === skin}
                onClick={() => onChangeSkin(skin)}
                sx={{
                  p: 0,
                  bgcolor: skin,
                  height: 'max-content',
                  width: 'max-content',
                }}
              >
                <Avatar
                  variant="square"
                  sx={{
                    bgcolor: skin,
                    color: skinColor === skin ? contrastColor(skin) : skin,
                    height: 48,
                    width: 48,
                  }}
                >
                  <CheckIcon />
                </Avatar>
              </ToggleButton>
            ))}
            <ToggleButton
              value={selectColor}
              selected={skinColor === selectColor}
              sx={{
                p: 0,
                height: 'max-content',
                width: 'max-content',
                bgcolor: selectColor,
              }}
            >
              <ColorPicker
                id="skin_picker"
                color={selectColor}
                onClose={(c: string) => {
                  setSelectColor(c);
                  setSkinColor(c);
                  onChangeSkin(c);
                }}
                size={48}
                variant="square"
                icon={skinColor === selectColor ? <CheckIcon /> : <PaletteIcon />}
              />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
