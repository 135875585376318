import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RandomIcon from '@mui/icons-material/CasinoOutlined';
import DownloadIcon from '@mui/icons-material/DownloadRounded';
import ExtensionOffIcon from '@mui/icons-material/ExtensionOff';
import FavoriteIcon from '@mui/icons-material/FavoriteRounded';
import InstallAppIcon from '@mui/icons-material/InstallMobileRounded';
import MoreOptionsIcon from '@mui/icons-material/MoreVertRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import ShareIcon from '@mui/icons-material/ShareRounded';
import TutorialIcon from '@mui/icons-material/SlowMotionVideoRounded';
import { alpha, colors, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { indigo } from '@mui/material/colors';
import { makeAndDownload } from 'util/maker';
import Padoru from 'util/padoru';
import { countDownload, countDownloadApp, sharePage } from 'util/share';
import SimpleModal from 'components/Modals';
import DonateModal from 'components/Modals/DonateModal';
import InstallAppModal from 'components/Modals/InstallAppModal';
import ProgressModal from 'components/Modals/ProgressModal';
import ShareWebSiteModal from 'components/Modals/ShareWebSite';
import { clearLocalPadoru, isRandomFeature, savePadoru } from 'util/padoru-api';

const contadorVisitON = String(process.env.REACT_APP_COUNTER_VISITOR) === 'true';

const calculateCantTabs = (): number => {
  const screenWidth = document.documentElement.clientWidth;
  const toolbarWidth = screenWidth - 32;
  const slides = Math.trunc(toolbarWidth / 80) - 1;
  const nTabs = slides < 1 ? 1 : slides;
  return nTabs;
};

interface Props {
  isMobile: boolean;
  isMd?: boolean;
  openTutorial: (o: boolean) => void;
  onNewPadoru: (o: Padoru) => void;
}

function ToolBarV2(props: Readonly<Props>) {
  const { t } = useTranslation();
  const isLg = useMediaQuery('(max-width:1199px)');
  const { isMobile, openTutorial, onNewPadoru } = props;
  const [openedModal, setOpenModal] = useState<boolean>(false);
  const [modal, setModal] = useState<JSX.Element>();
  const [openSecond, setOpenSecond] = useState<boolean>(false);

  const [openModalInstall, setOpenModalInstall] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent>();

  const openModal = () => setOpenModal(true);
  const closeModal = () => {
    setOpenModalInstall(false);
    setOpenModal(false);
  };

  const beforeinstallpromptFunc = (e: BeforeInstallPromptEvent) => {
    e.preventDefault();
    setDeferredPrompt(e);
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', beforeinstallpromptFunc as any);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeinstallpromptFunc as any);
    };
  }, [openModalInstall]);

  const newPadoru = () => {
    isRandomFeature(false);
    clearLocalPadoru();
    onNewPadoru(Padoru.zero('2'));
  };

  const randomPadoru = () => {
    isRandomFeature(true);
    clearLocalPadoru();
    onNewPadoru(Padoru.random('2'));
  };

  const downloadPadoru = async () => {
    setModal(<ProgressModal title={t('components.modals.downloading.title')} />);
    openModal();
    countDownload();
    await makeAndDownload();
    closeModal();
  };

  const shareWebSite = () => {
    if (isMobile) {
      sharePage({
        text: t('components.share.web_text'),
        url: '/',
        title: t('components.share.web_title'),
      });
    } else {
      setModal(<ShareWebSiteModal />);
      openModal();
    }
  };

  const handlerDonateBtn = () => {
    setModal(<DonateModal />);
    openModal();
  };

  const handlerDownloadBtn = () => {
    setOpenModalInstall(true);
  };

  const showDownloadAppDialog = useCallback(async () => {
    deferredPrompt?.prompt?.();
    const { outcome } = (await deferredPrompt?.userChoice) ?? {};
    if (outcome === 'dismissed') {
      closeModal();
    }
    if (outcome === 'accepted') {
      countDownloadApp();
    }
  }, [deferredPrompt]);

  const TOOLBAR_TABS = [
    {
      id: 'toolbar_new_btn',
      label: 'components.toolbar_v2.new',
      icon: <NewIcon />,
      func: newPadoru,
    },
    {
      id: 'toolbar_save_btn',
      label: 'components.toolbar_v2.save',
      icon: <SaveIcon />,
      func: savePadoru,
    },
    {
      id: 'toolbar_download_btn',
      label: 'components.toolbar_v2.download',
      icon: <DownloadIcon />,
      func: downloadPadoru,
    },
    {
      id: 'toolbar_random_btn',
      label: 'components.toolbar_v2.random',
      icon: <RandomIcon />,
      func: randomPadoru,
    },
    {
      id: 'toolbar_share_btn',
      label: 'components.toolbar_v2.share_web',
      icon: <ShareIcon />,
      func: shareWebSite,
    },
    {
      id: 'toolbar_tuto_btn',
      label: 'components.toolbar_v2.tutorial',
      icon: <TutorialIcon />,
      func: () => openTutorial(true),
    },
    {
      id: 'toolbar_install_btn',
      label: 'components.toolbar_v2.download_app',
      icon: <InstallAppIcon />,
      func: handlerDownloadBtn,
      hidden: !(window as any)?.BeforeInstallPromptEvent,
    },
    {
      id: 'toolbar_donate_btn',
      label: 'components.toolbar_v2.donate',
      icon: <FavoriteIcon />,
      func: handlerDonateBtn,
    },
  ];

  const numberOfTabs = calculateCantTabs() - 1;
  const firstTabs = TOOLBAR_TABS.slice(0, numberOfTabs);
  const secondTabs = TOOLBAR_TABS.slice(numberOfTabs);

  return (
    <Box id="toolbar_v2_wrapper">
      <SimpleModal open={openedModal} onClose={closeModal} btnClose>
        <Box>{modal}</Box>
      </SimpleModal>

      <SimpleModal open={openModalInstall} onClose={closeModal} btnClose>
        <InstallAppModal onClick={showDownloadAppDialog} loadingEvent={!deferredPrompt} />
      </SimpleModal>

      <AppBar
        position="static"
        component="footer"
        sx={{
          backgroundColor: alpha(colors.indigo[500], 0.5),
          bottom: 0,
          zIndex: 1000,
          fontSize: '0.5rem',
        }}
      >
        <Container maxWidth="xl" sx={{ mb: '-3em' }}>
          <Box id="toolbar_v2" sx={{ mt: -1 }}>
            <Paper
              id="toolbar_v2_paper"
              elevation={3}
              sx={{
                pb: 3.3,
                backgroundColor: indigo[50],
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
            >
              <Tabs
                id="toolbar_tabs"
                variant="fullWidth"
                textColor="inherit"
                //value={!isMd ? 'contador' : 'components.toolbar_v2.donate'}
                value="init"
              >
                <Tab
                  value="init"
                  sx={{
                    width: '1px !important',
                    minWidth: '1px !important',
                    maxWidth: '1px !important',
                    padding: '0px !important',
                  }}
                />

                {firstTabs.map(
                  tab =>
                    !tab.hidden && (
                      <Tab
                        id={tab.id}
                        key={tab.label}
                        icon={tab.icon}
                        label={t(tab.label)}
                        onClick={tab.func}
                        value={tab.label}
                        sx={{ fontSize: 'small' }}
                      />
                    ),
                )}

                {!secondTabs.length && (
                  <Tab
                    sx={
                      !isLg
                        ? {
                            fontSize: 'small',
                            width: 'max-content',
                            minWidth: 'max-content',
                            maxWidth: 'max-content',
                          }
                        : {
                            width: '1px',
                            minWidth: '1px',
                            maxWidth: '1px',
                            p: 0,
                          }
                    }
                    id="tab_contador"
                    value="contador"
                    disableRipple
                    iconPosition="bottom"
                    icon={<TabContador />}
                  />
                )}
                {!!secondTabs.length && (
                  <Tab
                    id="more_options"
                    icon={<MoreOptionsIcon />}
                    label="Menu"
                    onClick={() => setOpenSecond(true)}
                    value="more_opts"
                    sx={{ fontSize: 'small' }}
                  />
                )}
              </Tabs>

              {!!secondTabs.length && (
                <Drawer
                  anchor="bottom"
                  open={openSecond}
                  onClose={() => setOpenSecond(false)}
                  ModalProps={{ keepMounted: true }}
                >
                  <List>
                    {secondTabs.map(
                      tab =>
                        !tab.hidden && (
                          <ListItemButton
                            key={tab.label}
                            id={tab.label}
                            onClick={tab.func}
                          >
                            <ListItemIcon>{tab.icon}</ListItemIcon>
                            <ListItemText primary={t(tab.label)} />
                          </ListItemButton>
                        ),
                    )}

                    <ListItemButton>
                      <TabContador />
                    </ListItemButton>
                  </List>
                </Drawer>
              )}
            </Paper>
          </Box>
        </Container>
      </AppBar>
    </Box>
  );
}

function TabContador() {
  const { t } = useTranslation();
  return contadorVisitON ? (
    <Box id="contador_wrapper" width="max-content" m="auto">
      {t('components.footer.visitors')}
      <div id="sfc5424q1ma69bmae2ayk6ax1xh74f1zzb9">
        <img
          src="https://counter5.optistats.ovh/private/contadorvisitasgratis.php?c=5424q1ma69bmae2ayk6ax1xh74f1zzb9"
          title="contador_de_visitas"
          alt="contador_de_visitas"
        />
      </div>
    </Box>
  ) : (
    <Tooltip placement="top-start" title={t('components.footer.visitors_disabled')!}>
      <ExtensionOffIcon color="error" />
    </Tooltip>
  );
}

export default ToolBarV2;
