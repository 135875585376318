import type { TFunction } from 'i18next';
import { getPadoruList } from './padoru-api';
import { scrollTo } from './helpers';
import type { PadoruVersion } from 'interfaces/padoru';

type TaskFunction = () => void;
interface TutorialStep {
  selector: string;
  beforeFuncs: TaskFunction[];
  afterFuncs: TaskFunction[];
  content_key: StepKey;
  interaction?: boolean;
}
export interface TourStep {
  selector: string;
  content: string;
  stepInteraction?: boolean;
}

enum StepKeys {
  welcome = 'non_exists_center_content',
  name = 'padoru_name_card',
  preview = 'preview_wrapper',
  skin = 'selector_card_skin',
  form = 'selector_card_hair',
  form2 = 'form_v2_tabs',
  colors = 'selector_card_hair_colors',
  style_bar = 'form_v2_botonera_wrapper',
  //style_bar_colors = 'botonera_btns_wrapper',
  style_bar_tools = 'botonera_tools_wrapper',
  carrousel = 'carrousel_hair_middle',
  panel_selector = 'contents_tabs_btns',
  toolbar_fab = 'toolbar_fab_btn',
  new_padoru_ghost = 'new_padoru_btn_ghost',
  toolbar2 = 'toolbar_v2_paper',
  new_padoru = 'toolbar_new_btn',
  random_padoru = 'toolbar_random_btn',
  save_padoru = 'toolbar_save_btn',
  download_padoru = 'toolbar_download_btn',
  tutorial_btn = 'toolbar_tuto_btn',
  app_btn = 'toolbar_install_btn',
  storage_fab = 'storage_fab_btn',
  storage_drawer = 'storage_drawer_ghost',
  storage_actions = 'storage_card_0_actions',
  fin = 'toolbar_fab_btn',
}
type StepKey = keyof typeof StepKeys;

const lengthPadoruList = getPadoruList().length;

const sortedStepV1: StepKey[] = [
  'welcome',
  'name',
  'preview',
  'skin',
  'form',
  'colors',
  'carrousel',
  'toolbar_fab',
  'new_padoru_ghost',
  'save_padoru',
  'download_padoru',
  'storage_fab',
  'storage_drawer',
  lengthPadoruList ? 'storage_actions' : 'storage_drawer',
  'fin',
];

const sortedStepV2: StepKey[] = [
  'welcome',
  'preview',
  'form2',
  'style_bar',
  'download_padoru',
  'storage_fab',
  'storage_drawer',
  lengthPadoruList ? 'storage_actions' : 'storage_drawer',
  'tutorial_btn',
];

const sortedStepV2Mobile: StepKey[] = [
  'welcome',
  'preview',
  'form2',
  'style_bar_tools',
  'toolbar_fab',
  'new_padoru_ghost',
  'download_padoru',
  'storage_fab',
  'storage_drawer',
  lengthPadoruList ? 'storage_actions' : 'storage_drawer',
  'fin',
];

const mapSteps = (arr: StepKey[]): TutorialStep[] => {
  return arr.map(key => ({
    beforeFuncs: [],
    afterFuncs: [],
    selector: `#${StepKeys[key]}`,
    content_key: key,
  }));
};

const CLIENT_CONSTANT = 'cm_is_constant';
const CLIENT_V2 = 'cm_is_const_v2';
export const isFirstVisit = (v?: PadoruVersion): boolean => {
  const key_const = v === '2' ? CLIENT_V2 : CLIENT_CONSTANT;
  const is_client = localStorage.getItem(key_const);
  setTimeout(() => {
    localStorage.setItem(key_const, 'true');
  }, 10000);
  return !is_client;
};

export type ITutorial = InstanceType<typeof Tutorial>;

class Tutorial {
  stepKeys: StepKey[];
  steps: TutorialStep[];

  constructor(steps: StepKey[]) {
    this.stepKeys = steps;
    this.steps = mapSteps(steps);
  }

  before(keys: StepKey[], cbs: TaskFunction[] = []) {
    const from = this.stepKeys.findIndex(s => s === keys[0]),
      to = this.stepKeys.findIndex(s => s === keys[1]);
    for (let inx = from; inx <= to; inx++) {
      this.steps[inx].beforeFuncs = this.steps[inx].beforeFuncs.concat(cbs);
    }
  }

  after(keys: StepKey[], cbs: TaskFunction[] = []) {
    const from = this.stepKeys.findIndex(s => s === keys[0]),
      to = this.stepKeys.findIndex(s => s === keys[1]);
    for (let inx = from; inx <= to; inx++) {
      this.steps[inx].afterFuncs = this.steps[inx].afterFuncs.concat(cbs);
    }
  }

  runTasks(inx: number) {
    if (inx >= 0) {
      this.steps[inx - 1]?.afterFuncs.map(func => func());
    }
    if (inx < this.steps.length) {
      scrollTo(this.steps[inx]?.selector, 'end');
      this.steps[inx]?.beforeFuncs.map(func => func());
    }
  }

  generateSteps(t: TFunction) {
    const steps: TourStep[] = this.steps.map(st => {
      return {
        selector: st.selector,
        content: t(`pages.tutorial.${st.content_key}`),
        stepInteraction: st.interaction,
      };
    });

    return steps;
  }
}

const tutorial = new Tutorial(sortedStepV1);
const tutorialV2 = new Tutorial(sortedStepV2);
const tutoV2Mobile = new Tutorial(sortedStepV2Mobile);

export { tutorial, tutorialV2, tutoV2Mobile };
