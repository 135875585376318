import { filename } from './helpers';
import { makeImageBlob } from './maker';
import type { PadoruUri } from 'interfaces/padoru';

interface SharePadoru {
  name: string;
  padoru: PadoruUri;
  text?: string;
}

interface SharePage {
  title?: string;
  text: string;
  url: string;
}

const share = (data: globalThis.ShareData) => {
  if (navigator.canShare?.(data)) {
    navigator.share?.(data).catch?.(console.error);
  } else {
    alert('Your browser does not support sharing');
  }
};

export const shareImage = async (data: SharePadoru) => {
  try {
    const blob = await makeImageBlob(data.padoru);
    const file = new File([blob], filename(data.name, 'jpeg'), { type: 'image/jpeg' });
    return share({ files: [file], text: data.text });
  } catch (error: any) {
    console.error(error);
  }
};

export const sharePage = (data: SharePage) => {
  return share({ title: data.title, text: data.text, url: data.url });
};

export const canShare = () => {
  return (
    !!navigator.canShare &&
    !!navigator.share &&
    typeof navigator.canShare === 'function' &&
    typeof navigator.share === 'function'
  );
};

const contadorDownloadsON = String(process.env.REACT_APP_COUNTER_DOWNLOAD) === 'true';
export const countDownload = () => {
  const urlCounter =
    'https://counter4.optistats.ovh/private/contadorvisitasgratis.php?c=dz5kacbb28s7f2jj7cmhfn55pembxlj5';
  if (contadorDownloadsON) {
    fetch(urlCounter, { mode: 'no-cors' }).then(() => console.log('Image Downloaded!'));
  }
};

const contadorDownAppON = String(process.env.REACT_APP_COUNTER_DOWNLOAD_APP) === 'true';
export const countDownloadApp = () => {
  const urlCounter =
    'https://counter8.optistats.ovh/private/contadorvisitasgratis.php?c=eslu57tlmswty22cuehu1wg62f1j9kuu';
  if (contadorDownAppON) {
    fetch(urlCounter, { mode: 'no-cors' }).then(() => console.log('APP Downloaded!'));
  }
};
