import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Padoru from 'util/padoru';
import StorageBar from 'components/ToolBar/StorageBar';
import ToolBar from './ToolBar';

interface Props {
  openTutorial: (o: boolean) => void;
  onNewPadoru: (o: Padoru) => void;
  onChangeName: (s: string) => void;
  onRestorePadoru: (p: Padoru) => void;
  padoruName: string;
}

function ToolBarV2Mobile(props: Props) {
  const { t } = useTranslation();
  const { padoruName, onChangeName, onRestorePadoru, openTutorial } = props;
  const [editingName, setPadoruName] = useState<string>('');

  useEffect(() => {
    setPadoruName(padoruName);
  }, [padoruName]);

  const handleBlurName = () => {
    if (editingName) {
      onChangeName(editingName);
    } else {
      alert(t('components.work_area.error_name'));
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <StorageBar
        v="1"
        isMobile
        onRestorePadoru={onRestorePadoru}
        sx={{ top: 0, position: 'relative' }}
      />

      <Box id="padoru_name_card" p={1} px={1.5} flex={1}>
        <TextField
          id="name_padoru_input"
          label={t('components.work_area.name_input')}
          variant="standard"
          onChange={(e: any) => setPadoruName(e.target.value)}
          onBlur={handleBlurName}
          value={editingName}
          error={!editingName}
          fullWidth
        />
      </Box>
      <ToolBar
        isMobile
        v2
        onNewPadoru={onRestorePadoru}
        openTutorial={openTutorial}
        sx={{ top: 0, position: 'relative' }}
      />
    </Stack>
  );
}

const ToolBarV2MobileMemo = memo(ToolBarV2Mobile, (prev, next) => {
  return prev.padoruName === next.padoruName;
});

/**
 * return true = no render
 * return false = render
 */

export default ToolBarV2MobileMemo;
