import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { AfectedSkinParts } from 'CONSTANTS';
import Padoru from 'util/padoru';
import BotoneraColors from 'components/Botonera/BtnColors';
import BotoneraTools from 'components/Botonera/BtnTools';
import Preview from 'components/Preview';
import type { GradiantMode } from 'interfaces/svg';
import type { ChangeColorFunctionV2, ChangeGradiantFunctionV2 } from 'interfaces/app';
import type { KeyPart, PadoruVersion } from 'interfaces/padoru';

interface Props {
  partTab: KeyPart;
  padoru: Padoru;
  padoruName: string;
  onChangeColor: ChangeColorFunctionV2;
  onChangeGradiant: ChangeGradiantFunctionV2;
  v?: PadoruVersion;
}

const defaultGradiant: GradiantMode = { blur: 30, mode: 'h', tier: 55 };

function WorkAreaMobile(props: Props) {
  const { padoru, padoruName, partTab, onChangeColor, onChangeGradiant, v } = props;

  const gradiant = padoru.parts.get(partTab)?.gradiant ?? defaultGradiant;
  const colors = padoru.parts.get(partTab)?.colors ?? [];
  const hasColors = !!colors?.length;

  return (
    <Stack
      id="work_area_mobile"
      direction="row"
      justifyContent="space-between"
      spacing={1}
    >
      <Box id="btns_color_wrapper">
        <Paper id="btns_color_paper" sx={{ p: 1 }} elevation={2}>
          <BotoneraColors
            partTab={partTab}
            colors={colors}
            onChangeColor={onChangeColor([partTab])}
            orientation="vertical"
            disabled={!hasColors || AfectedSkinParts.includes(partTab)}
            warningMode={gradiant.mode === 'l'}
            floating
          />
        </Paper>
      </Box>

      <Box id="preview_wrapper_mobile">
        <Preview padoru={padoru} padoruName={padoruName} isMobile={false} v={v} />
      </Box>

      <Box id="btns_tools_wrapper" pt={1}>
        <Paper id="btns_tools_papper" sx={{ p: 0.5 }} elevation={2}>
          <BotoneraTools
            onChangeGradiant={onChangeGradiant(partTab)}
            gradiant={gradiant}
            colors={colors}
            orientation="vertical"
            disabled={!hasColors || AfectedSkinParts.includes(partTab)}
            floating
          />
        </Paper>
      </Box>
    </Stack>
  );
}

export default WorkAreaMobile;
