interface optsConverter {
  width: number;
  height: number;
}

const svg2png = (
  svgUri: string,
  opts: optsConverter = { height: 60, width: 60 },
): Promise<string> => {
  const canvas = document.createElement('canvas');
  canvas.height = opts.height;
  canvas.width = opts.width;
  const ctx = canvas.getContext('2d')!;

  return new Promise(done => {
    const image = new Image();
    image.src = svgUri;
    image.onload = function () {
      ctx.clearRect(0, 0, opts.width, opts.height);
      ctx.drawImage(image, 0, 0, opts.width, opts.height);
      const pngUri = canvas.toDataURL();
      done(pngUri);
    };
  });
};

export default svg2png;
