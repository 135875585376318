import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RandomIcon from '@mui/icons-material/CasinoOutlined';
import ToolBarIcon from '@mui/icons-material/ConstructionRounded';
import DownloadIcon from '@mui/icons-material/DownloadRounded';
import FavoriteIcon from '@mui/icons-material/FavoriteRounded';
import InstallAppIcon from '@mui/icons-material/InstallMobileRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import ShareIcon from '@mui/icons-material/ShareRounded';
import TutorialIcon from '@mui/icons-material/SlowMotionVideoRounded';
import type { SxProps } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import eventManager from 'util/EventEmitter';
import { makeAndDownload } from 'util/maker';
import Padoru from 'util/padoru';
import { countDownload, countDownloadApp, sharePage } from 'util/share';
import { tutoV2Mobile, tutorial } from 'util/tutorial';
import SimpleModal from 'components/Modals';
import DonateModal from 'components/Modals/DonateModal';
import InstallAppModal from 'components/Modals/InstallAppModal';
import ProgressModal from 'components/Modals/ProgressModal';
import ShareWebSiteModal from 'components/Modals/ShareWebSite';
import { clearLocalPadoru, isRandomFeature, savePadoru } from 'util/padoru-api';
import { css } from './styles';

interface Props {
  isMobile: boolean;
  openTutorial: (o: boolean) => void;
  onNewPadoru: (o: Padoru) => void;
  v2: boolean;
  sx?: SxProps;
}

function ToolBar(props: Props) {
  const { t } = useTranslation();
  const { isMobile, openTutorial, onNewPadoru, v2, sx = {} } = props;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [openedModal, setOpenModal] = useState<boolean>(false);
  const [modal, setModal] = useState<JSX.Element>();

  const [openModalInstall, setOpenModalInstall] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent>();

  const openModal = () => setOpenModal(true);
  const closeModal = () => {
    setOpenModalInstall(false);
    setOpenModal(false);
  };
  const openMenu = () => setShowMenu(true);
  const closeMenu = () => {
    setShowMenu(false);
    setOpenModalInstall(false);
    eventManager.emmit('open_tutorial', false);
  };

  const beforeinstallpromptFunc = (e: BeforeInstallPromptEvent) => {
    e.preventDefault();
    setDeferredPrompt(e);
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', beforeinstallpromptFunc as any);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeinstallpromptFunc as any);
    };
  }, [openModalInstall]);

  const newPadoru = () => {
    isRandomFeature(false);
    clearLocalPadoru();
    onNewPadoru(Padoru.zero(v2 ? '2' : '1'));
    closeMenu();
  };

  const randomPadoru = () => {
    isRandomFeature(true);
    clearLocalPadoru();
    onNewPadoru(Padoru.random(v2 ? '2' : '1'));
    closeMenu()
  };

  const downloadPadoru = async () => {
    setModal(<ProgressModal title={t('components.modals.downloading.title')} />);
    openModal();
    countDownload();
    await makeAndDownload();
    closeModal();
  };

  const shareWebSite = () => {
    if (isMobile) {
      sharePage({
        text: t('components.share.web_text'),
        url: '/',
        title: t('components.share.web_title'),
      });
    } else {
      setModal(<ShareWebSiteModal />);
      openModal();
    }
  };

  const handlerDonateBtn = () => {
    setModal(<DonateModal />);
    openModal();
  };

  const handlerDownloadBtn = () => {
    setOpenModalInstall(true);
  };

  const showDownloadAppDialog = useCallback(async () => {
    deferredPrompt?.prompt?.();
    const { outcome } = (await deferredPrompt?.userChoice) ?? {};
    if (outcome === 'dismissed') {
      closeModal();
    }
    if (outcome === 'accepted') {
      countDownloadApp();
    }
  }, [deferredPrompt]);

  useEffect(() => {
    tutorial.before(['welcome', 'fin'], [closeMenu]);
    tutorial.before(['new_padoru_ghost', 'download_padoru'], [openMenu]);

    tutoV2Mobile.before(['welcome', 'fin'], [closeMenu]);
    tutoV2Mobile.before(['new_padoru_ghost', 'download_padoru'], [openMenu]);
  }, []);

  return (
    <div>
      <SimpleModal open={openedModal} onClose={closeModal} btnClose>
        <Box>{modal}</Box>
      </SimpleModal>

      <SimpleModal open={openModalInstall} onClose={closeModal} btnClose>
        <InstallAppModal onClick={showDownloadAppDialog} loadingEvent={!deferredPrompt} />
      </SimpleModal>

      <Fab
        id="toolbar_fab_btn"
        sx={{ ...css.fab, ...css.fabRight, ...sx }}
        onClick={openMenu}
      >
        <ToolBarIcon />
      </Fab>

      <Box
        id="new_padoru_btn_ghost"
        sx={{
          ...css.drawerGhost,
          right: 0,
          height: 'calc(32px + 1rem)',
          top: 'calc(32px + 1.25rem)',
        }}
      >
        I
      </Box>

      <Drawer
        PaperProps={{ sx: css.drawerPaper }}
        anchor="right"
        open={showMenu}
        onClose={closeMenu}
      >
        <Typography variant="h6" sx={{ mx: 2, mt: 2 }}>
          {t('components.toolbar.menu_title')}
        </Typography>

        <Box id="toolbar_drawer" sx={{ overflow: 'auto' }}>
          <List>
            <ListItemButton id="toolbar_new_btn" onClick={newPadoru}>
              <ListItemIcon>
                <NewIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.new')} />
            </ListItemButton>

            <ListItemButton id="toolbar_random_btn" onClick={randomPadoru}>
              <Badge color="secondary" badgeContent="BETA">
                <ListItemIcon>
                  <RandomIcon />
                </ListItemIcon>
                <ListItemText primary={t('components.toolbar.random')} />
              </Badge>
            </ListItemButton>

            <ListItemButton id="toolbar_save_btn" onClick={savePadoru}>
              <ListItemIcon>
                <SaveIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.save')} />
            </ListItemButton>

            <ListItemButton id="toolbar_download_btn" onClick={downloadPadoru}>
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.download')} />
            </ListItemButton>

            <ListItemButton id="toolbar_share_btn" onClick={shareWebSite}>
              <ListItemIcon>
                <ShareIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.share_web')} />
            </ListItemButton>

            <ListItemButton id="toolbar_tuto_btn" onClick={() => openTutorial(true)}>
              <ListItemIcon>
                <TutorialIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.tutorial')} />
            </ListItemButton>

            {(window as any)?.BeforeInstallPromptEvent && (
              <ListItemButton id="toolbar_tutorial_btn" onClick={handlerDownloadBtn}>
                <ListItemIcon>
                  <InstallAppIcon />
                </ListItemIcon>
                <ListItemText primary={t('components.toolbar.download_app')} />
              </ListItemButton>
            )}

            <ListItemButton id="toolbar_donate_btn" onClick={handlerDonateBtn}>
              <ListItemIcon>
                <FavoriteIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.donate')} />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default ToolBar;
