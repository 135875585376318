import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import BtnColor from './BtnColor';
import { KeyPart } from 'interfaces/padoru';

interface Props {
  partTab: KeyPart;
  colors: string[];
  onChangeColor: (colorN: number) => (color: string) => void;
  disabled: boolean;
  orientation?: 'horizontal' | 'vertical';
  floating?: boolean;
  warningMode?: boolean;
}

function BotoneraColors(props: Readonly<Props>) {
  const {
    partTab,
    colors,
    onChangeColor,
    disabled,
    orientation = 'vertical',
    floating = false,
    warningMode = false,
  } = props;

  const generateSwap = (i1: number, i2: number) => () => {
    const ca = colors[i1],
      cb = colors[i2];
    onChangeColor(i1)(cb);
    onChangeColor(i2)(ca);
  };

  return (
    <Box id="botonera_btns_wrapper">
      <Stack
        direction={orientation === 'vertical' ? 'column' : 'row'}
        spacing={1}
        pb={1}
        id="stack_btns_colors"
      >
        {(colors.length ? colors : ['#333333']).map((c, i) => (
          <Stack
            direction={orientation === 'vertical' ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
            spacing={1}
            key={partTab + i}
          >
            <BtnColor
              color={c}
              label={`Color ${i + 1}`}
              onChangeColor={onChangeColor(i)}
              disabled={disabled}
              floating={floating}
              warningMode={warningMode && i === 1}
            />
            {i < colors.length - 1 && (
              <IconButton
                onClick={generateSwap(i, i + 1)}
                sx={{ p: 0 }}
                style={{ backgroundColor: 'transparent' }}
                disabled={disabled}
              >
                {orientation === 'vertical' ? (
                  <SwapVertIcon fontSize="medium" />
                ) : (
                  <SwapHorizIcon fontSize="medium" />
                )}
              </IconButton>
            )}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}

// debe renderizar para crear bien el onChange color sino tiene el anterior
/*
const BotoneraColorsMemo = memo(BotoneraColors, (prev, next) => {
  const sameTab = prev.partTab === next.partTab;
  const prevColors = JSON.stringify(prev.colors);
  const nextColors = JSON.stringify(next.colors);
  const sameColors = prevColors === nextColors;
  const shouldRender = !sameTab || !sameColors;
  console.log(' R botonera colores', {
    shouldRender,
    sameTab,
    sameColors,
    prevColors,
    nextColors,
  });
  return !shouldRender;
});

export default BotoneraColorsMemo;
*/

export default BotoneraColors;
