import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { AfectedSkinParts } from 'CONSTANTS';
import BotoneraColors from './BtnColors';
import BotoneraTools from './BtnTools';
import type { GradiantMode } from 'interfaces/svg';
import type { ChangeColorFunctionV2, ChangeGradiantFunctionV2 } from 'interfaces/app';

const defaultGradiant: GradiantMode = { blur: 30, mode: 'l', tier: 55 };

interface Props {
  partTab: string | any;
  onChangeColor: ChangeColorFunctionV2;
  onChangeGradiant: ChangeGradiantFunctionV2;
  colors: string[];
  gradiant?: GradiantMode;
  orientation?: 'horizontal' | 'vertical';
}

export default function BotoneraMakerV2Web(props: Readonly<Props>) {
  const {
    partTab,
    onChangeColor,
    onChangeGradiant,
    colors,
    gradiant = defaultGradiant,
    orientation = 'vertical',
  } = props;

  const hasColors = !!colors?.length;

  return (
    <Box
      id="form_v2_botonera_wrapper"
      sx={{
        zIndex: 500,
        position: 'absolute',
        top: orientation === 'vertical' ? 16 * 8 : 'unset',
        bottom: orientation === 'horizontal' ? 0 : 'unset',
        left: 2 * 8,
      }}
    >
      <Paper
        id="botonera_paper"
        elevation={3}
        sx={{
          position: 'relative',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          backgroundColor: grey[100],
        }}
      >
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" flexItem variant="middle" />}
          spacing={0.5}
          p={1}
        >
          <BotoneraColors
            partTab={partTab}
            colors={colors}
            onChangeColor={onChangeColor([partTab])}
            orientation={orientation}
            disabled={!hasColors || AfectedSkinParts.includes(partTab)}
            warningMode={gradiant.mode == 'l'}
          />

          <BotoneraTools
            onChangeGradiant={onChangeGradiant(partTab)}
            gradiant={gradiant}
            colors={colors}
            orientation={orientation}
            disabled={!hasColors || AfectedSkinParts.includes(partTab)}
          />
        </Stack>
      </Paper>
    </Box>
  );
}
