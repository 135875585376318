import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface Props {
  onClick: () => void;
  loadingEvent: boolean;
}

function InstallAppModal(props: Props) {
  const { t } = useTranslation();
  const { onClick, loadingEvent } = props;

  return (
    <div>
      <Typography variant="h6" component="h2">
        {t('components.modals.download_app.title')}
      </Typography>
      <Typography my={2} sx={{ whiteSpace: 'pre-line' }}>
        {t('components.modals.download_app.content')}
      </Typography>
      <Box textAlign="center" mt={2.7}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={loadingEvent}
        >
          {loadingEvent ? (
            <CircularProgress size="1.5rem" color="inherit" />
          ) : (
            t('components.modals.download_app.btn_download')
          )}
        </Button>
      </Box>
    </div>
  );
}

export default InstallAppModal;
