import logo_bottom from 'assets/imgs/pmagua.png';
import { getUriPadoru } from './padoru-api';
import { marcaDeAguaProps as pmo } from '../CONSTANTS';
import { filename } from './helpers';
import type { PadoruUri, PartUri } from 'interfaces/padoru';

interface OptionsMaker {
  padoru?: PadoruUri;
  responsetype: 'datauri' | 'blob' | 'download';
  height: number;
  width: number;
}

const makeImage = async (opts: OptionsMaker): Promise<string | Blob> => {
  const padoruUri = opts.padoru || getUriPadoru();
  const canvas = document.createElement('canvas');
  canvas.width = opts.width;
  canvas.height = opts.height;

  const ctx = canvas.getContext('2d')!;
  const composite = funcComposite(ctx, opts.width, opts.height);
  const orderer = padoruUri.parts.sort((a, b) => a.zInx - b.zInx);

  const drawImages = (parts: PartUri[]): any => {
    return composite(parts).then((arr: PartUri[]) => {
      if (arr.length) return drawImages(arr);
    });
  };

  // ----- white bg for jpeg
  ctx.fillStyle = '#FFFFFF';
  ctx.fillRect(0, 0, 355, 355);
  // ----------

  await drawImages(orderer);
  await addMarcaAgua(ctx);

  return new Promise(done => {
    switch (opts.responsetype) {
      case 'datauri':
        return done(canvas.toDataURL());
      case 'download':
        canvas.toBlob(blob => download(blob!, padoruUri.name, done));
        break;
      case 'blob':
        return canvas.toBlob(blob => done(blob!));
    }
  });
};

const funcComposite =
  (ctx: CanvasRenderingContext2D, xz: number, yz: number) =>
  (partsUris: PartUri[]): Promise<PartUri[]> =>
    new Promise((resolve, reject) => {
      const img = partsUris.shift();
      const imgToDraw = new Image();
      imgToDraw.src = img!.uri;
      imgToDraw.onload = () => {
        ctx.drawImage(imgToDraw, 0, 0, xz, yz);
        resolve(partsUris);
      };
      imgToDraw.onerror = reject;
    });

const addMarcaAgua = (ctx: CanvasRenderingContext2D) =>
  new Promise(done => {
    const imgToDraw = new Image();
    imgToDraw.src = logo_bottom;
    imgToDraw.onload = () => {
      ctx.drawImage(imgToDraw, pmo.xInit, pmo.yInit, pmo.width, pmo.height);
      done(null);
    };
    imgToDraw.onerror = done;
  });

const makeImageBlob = (p: PadoruUri) => {
  return makeImage({
    padoru: p,
    responsetype: 'blob',
    height: 355,
    width: 355,
  }) as Promise<Blob>;
};

const makeAndDownload = (p?: PadoruUri) => {
  return makeImage({
    padoru: p,
    responsetype: 'download',
    height: 355,
    width: 355,
  }) as Promise<string>;
};

const makeThumbnail = (p?: PadoruUri) => {
  return makeImage({
    padoru: p,
    responsetype: 'datauri',
    height: 200,
    width: 200,
  }) as Promise<string>;
};

const download = (blob: Blob, name: string, cb?: (a: string) => void) => {
  const link = document.createElement('a');
  const fileName = filename(name, 'jpeg');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  cb?.(fileName);
};

export { makeAndDownload, makeThumbnail, makeImageBlob };
