import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { AfectedSkinParts } from 'CONSTANTS';
import eventManager from 'util/EventEmitter';
import Padoru from 'util/padoru';
import { isFirstVisit } from 'util/tutorial';
import { getLocalPadoruActual, setLocalPadoruActual } from 'util/padoru-api';
import Maker2View from './Maker2View';
import type { GradiantMode } from 'interfaces/svg';
import type {
  ChangeColorFunctionV2,
  ChangeGradiantFunctionV2,
  ChangePartFunctionV2,
} from 'interfaces/app';
import type { KeyPart } from 'interfaces/padoru';

export default function MakerV2Contaienr() {
  const actualFromLocal = Padoru.toV(getLocalPadoruActual(), '2');
  const firstVisit = isFirstVisit('2');

  const [padoruActual, setPadoruActual] = useState<Padoru>(actualFromLocal);
  const [padoruName, setPadoruName] = useState<string>(actualFromLocal.name);

  const [openTutorial, setOpenTutorial] = useState<boolean>(firstVisit);
  const [forceReset, setReset] = useState<number>(0);

  useEffect(() => {
    const local = Padoru.toV(getLocalPadoruActual(), '2');
    setPadoruActual(local);
    setPadoruName(local.name);
    eventManager.subscribe('open_tutorial', (lock: boolean) => {
      setTimeout(() => (document.body.style.overflowY = lock ? 'hidden' : 'auto'), 250);
    });
  }, []);

  useEffect(() => {
    padoruActual.name = padoruName;
    setLocalPadoruActual(padoruActual);
  }, [padoruActual]);

  useEffect(() => {
    const updated = { ...padoruActual };
    updated.name = padoruName;
    setPadoruActual(updated);
  }, [padoruName]);

  const onChangePart: ChangePartFunctionV2 = useCallback(
    (key_part: KeyPart) => {
      return (uuid: string) => {
        const updated = { ...padoruActual };
        const updatePart = updated.parts.get(key_part);
        if (updatePart) {
          updatePart.uuid = uuid;
          updated.parts.set(key_part, updatePart);
        }
        setPadoruActual(updated);
      };
    },
    [padoruActual, forceReset],
  );

  const onChangeColor: ChangeColorFunctionV2 = useCallback(
    (partsKeys: KeyPart[]) => (colorN: number) => {
      return (color: string) => {
        const updated = { ...padoruActual };
        partsKeys.forEach((key: KeyPart) => {
          const updatePart = updated.parts.get(key);
          if (updatePart) {
            updatePart.colors[colorN] = color;
            updated.parts.set(key, updatePart);
          }
        });
        setPadoruActual(updated);
      };
    },
    [padoruActual, forceReset],
  );

  const onChangeGradiant: ChangeGradiantFunctionV2 = useCallback(
    (key_part: KeyPart) => {
      return (gradiant: GradiantMode) => {
        const updated = { ...padoruActual };
        const updatePart = updated.parts.get(key_part);
        if (updatePart) {
          updatePart.gradiant = gradiant;
          updated.parts.set(key_part, updatePart);
        }
        setPadoruActual(updated);
      };
    },
    [padoruActual, forceReset],
  );

  const onChangeSkin = useMemo(
    () => onChangeColor(AfectedSkinParts)(0),
    [padoruActual, forceReset],
  );

  const onNewOrRestorePadoru = useCallback(
    (p: Padoru) => {
      setReset(Date.now());
      setLocalPadoruActual(p);
      setPadoruActual(p);
      setPadoruName(p.name);
    },
    [forceReset],
  );

  return (
    <Box id="maker_2_container">
      <Maker2View
        padoru={padoruActual}
        padoruName={padoruName}
        onChangeName={setPadoruName}
        onChangeSkin={onChangeSkin}
        onChangePart={onChangePart}
        onChangeColor={onChangeColor}
        onChangeGradiant={onChangeGradiant}
        onRestorePadoru={onNewOrRestorePadoru}
        isFirstVisit={firstVisit}
        openTuto={openTutorial}
        openTutorial={setOpenTutorial}
      />
    </Box>
  );
}
