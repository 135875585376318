import { defaults } from 'lodash';
import Padoru from 'util/padoru';
import SelectorCard from 'components/SelectorCard';
import type {
  ChangeColorFunction,
  ChangePartFunction,
  ISchema,
  SelectedsUuid,
} from 'interfaces/app';

interface Props {
  onChangePart: ChangePartFunction;
  onChangeColor: ChangeColorFunction;
  padoru: Padoru;
  Schema: ISchema;
  isMobile: boolean;
  forceReset: number;
  initUuids: SelectedsUuid;
}

export function FormStyled(props: Props) {
  const { onChangePart, onChangeColor, padoru, Schema, isMobile, forceReset, initUuids } =
    props;

  return (
    <div id="form_div">
      {Schema.map((sch, key) => (
        <SelectorCard
          key={key}
          id={`selector_card_${sch.sectionKey}`}
          carrousels={sch.parts}
          name={sch.sectionName}
          colors={defaults(
            padoru.parts.get(sch.parts[0].key)?.colors ?? [],
            sch?.defaultColors,
          ).join()}
          skinColors={(padoru.parts.get('head')?.colors ?? []).join()}
          editableColor={!!sch?.defaultColors?.length}
          onChangePart={onChangePart}
          onChangeColor={onChangeColor}
          isMobile={isMobile}
          initUuids={initUuids}
          forceReset={forceReset}
        />
      ))}
    </div>
  );
}
