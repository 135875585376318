import ReactDOMServer from 'react-dom/server';
import svgToDataURL from 'svg-to-dataurl';
import { Colors } from '../CONSTANTS';
import type { JSvg, SvgPiece } from 'interfaces/svg';
import type { CustomColor, Part } from 'interfaces/padoru';

type Color = keyof typeof Colors;
const fill = (g: SvgPiece, custom: CustomColor): string => {
  let color: string = Colors[g.color.toUpperCase() as Color] || g.color;
  if (/^color([0-9]+)/.test(g.color.toLowerCase())) {
    const num = Number(g.color.toLowerCase().replace('color', ''));
    color = custom[num];
  }
  return color;
};

const viewBox = (json: JSvg, isIcon: boolean): string => {
  const defaultBox = '0 0 355 355';
  return !isIcon ? defaultBox : json.iconBox || defaultBox;
};

const opacity = (g: SvgPiece): string => {
  return String(g.opacity || 1);
};

/** @deprecated */
export class CustomSvg {
  tag: JSX.Element;
  constructor(json: JSvg, colors: CustomColor, isIcon?: boolean) {
    this.tag = (
      <svg
        id={json.name}
        xmlns="http://www.w3.org/2000/svg"
        width={!!isIcon ? '60' : '355'}
        height={!!isIcon ? '60' : '355'}
        viewBox={viewBox(json, !!isIcon)}
      >
        {json.pieces.map((g: SvgPiece, kg: number) => (
          <g
            id={`${json.name}_${kg}`}
            key={kg}
            opacity={opacity(g)}
            fill={fill(g, colors)}
          >
            {g.paths.map((path: string, kpath: number) => (
              <path id={`${json.name}_${kg}_${kpath}`} key={kpath} d={path}></path>
            ))}
          </g>
        ))}
      </svg>
    );
  }

  svg(): JSX.Element {
    return this.tag;
  }

  renderString(): string {
    return ReactDOMServer.renderToStaticMarkup(this.tag);
  }

  dataUrl(): string {
    const str = ReactDOMServer.renderToStaticMarkup(this.tag);
    return svgToDataURL(str);
  }
}

export class CustomSvgV2 {
  tag: JSX.Element;
  constructor(json: JSvg, cfg: Part, isIcon?: boolean) {
    if (!cfg.gradiant) {
      cfg.gradiant = { mode: 'l', blur: 30, tier: 100 };
    }

    let blur1 = cfg.gradiant.blur / 100,
      blur2 = (100 - cfg.gradiant.blur) / 100;
    const cA = cfg.gradiant.tier / 100,
      cB = (100 - cfg.gradiant.tier) / 100;

    if (['d1', 'd2'].includes(cfg.gradiant.mode)) {
      blur1 = cfg.gradiant.tier / 2 / 100 + (cfg.gradiant.blur - 50) / 100;
      blur2 = cfg.gradiant.tier / 2 / 100 - (cfg.gradiant.blur - 50) / 100;
    }

    this.tag = (
      <svg
        id={json.name}
        xmlns="http://www.w3.org/2000/svg"
        width={!!isIcon ? '60' : '355'}
        height={!!isIcon ? '60' : '355'}
        viewBox={viewBox(json, !!isIcon)}
      >
        <defs>
          <linearGradient id={`${json.uuid}_l`} x1={0} y1={0} x2={1} y2={1}>
            <stop offset="100%" stopColor={cfg.colors[0]} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={`${json.uuid}_d1`} x1={0} y1={0} x2={1} y2={1}>
            <stop offset={blur1} stopColor={cfg.colors[1]} stopOpacity={1} />
            <stop offset={blur2} stopColor={cfg.colors[0]} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={`${json.uuid}_d2`} x1={1} y1={0} x2={0} y2={1}>
            <stop offset={blur1} stopColor={cfg.colors[1]} stopOpacity={1} />
            <stop offset={blur2} stopColor={cfg.colors[0]} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={`${json.uuid}_v`} x1={cB} y1={cB} x2={1} y2={cB}>
            <stop offset={blur1} stopColor={cfg.colors[0]} stopOpacity={1} />
            <stop offset={blur2} stopColor={cfg.colors[1]} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={`${json.uuid}_h`} x1={cA} y1={cB} x2={cA} y2={1}>
            <stop offset={blur1} stopColor={cfg.colors[0]} stopOpacity={1} />
            <stop offset={blur2} stopColor={cfg.colors[1]} stopOpacity={1} />
          </linearGradient>
        </defs>
        {json.pieces.map((g: SvgPiece, kg: number) => (
          <g
            id={`${json.name}_${kg}`}
            key={kg}
            opacity={opacity(g)}
            fill={
              g.gradiant
                ? `url(#${json.uuid}_${cfg.gradiant!.mode})`
                : fill(g, cfg.colors)
            }
          >
            {g.paths.map((path: string, kpath: number) => (
              <path id={`${json.name}_${kg}_${kpath}`} key={kpath} d={path}></path>
            ))}
          </g>
        ))}
      </svg>
    );
  }

  svg(): JSX.Element {
    return this.tag;
  }

  renderString(): string {
    return ReactDOMServer.renderToStaticMarkup(this.tag);
  }

  dataUrl(): string {
    const str = ReactDOMServer.renderToStaticMarkup(this.tag);
    return svgToDataURL(str);
  }
}
