/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import type { SxProps } from '@mui/system';

/**
 * te magic: https://developers.google.com/web/updates/2017/09/sticky-headers
 */
interface Props {
  children: any;
  zindex?: number;
  stickySx?: SxProps;
  onChange?: (on: boolean) => any;
}

interface WrapBoxProps {
  width: number | string;
  height: number | string;
}

export function StickyBoxStyled(props: Props) {
  const { children, onChange, zindex = 250, stickySx = {} } = props;
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [wrapBox, setWrapBox] = useState<WrapBoxProps>({ width: 0, height: 0 });

  const ref = useRef();
  const refWrap = useRef();

  // mount
  useEffect(() => {
    const cachedRef = ref.current as any;
    const wrapedRef = refWrap.current as any;
    const observer = new IntersectionObserver(
      ([e]) => {
        const bounce: DOMRect = cachedRef.getBoundingClientRect();
        const bounceWrap: DOMRect = wrapedRef.getBoundingClientRect();
        setIsSticky(e.intersectionRatio < 1 && bounce.top < 0 && bounceWrap.top < 1);
      },
      {
        threshold: [1],
      },
    );
    observer.observe(cachedRef);

    // ----------------------------------------------

    const observerWrap = new IntersectionObserver(
      ([e]) => {
        const bounceWrap: DOMRect = wrapedRef.getBoundingClientRect();
        setIsSticky(e.intersectionRatio < 1 && bounceWrap.top <= 0);
      },
      {
        threshold: [1],
      },
    );

    observerWrap.observe(wrapedRef);
    // ----------------------------------------------------

    // unmount
    return function () {
      observer.unobserve(cachedRef);
      observerWrap.unobserve(wrapedRef);
    };
  }, []);

  const WBox = () => {
    const cachedRef = ref.current as any;
    const bounce: DOMRect = cachedRef.getBoundingClientRect();
    if (!wrapBox.height || Number(wrapBox.height) < Number(wrapBox.width) / 2) {
      setWrapBox({
        width: bounce.width,
        height: bounce.width - bounce.left, // left creo q es padding, y como la img es cuadrada se puede usar width
      });
    }
  };

  // custom hook
  useEffect(() => {
    onChange && onChange(isSticky);
  }, [isSticky]);

  const stickySxProps = isSticky
    ? { ...stickySx, mt: 'unset !important', marginTop: 'unset !important' }
    : {};

  return (
    <Box
      ref={refWrap}
      id="stick-wrapper"
      sx={{
        position: 'relative',
        height: wrapBox.height || 'auto',
        width: wrapBox.width || 'auto',
      }}
    >
      <Box
        id="stick"
        ref={ref}
        onLoad={WBox}
        sx={{
          position: isSticky ? 'fixed' : 'relative',
          top: '-1px !important',
          zIndex: zindex,
          height: 'inherit',
          width: 'inherit',
          ...stickySxProps,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
