import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import is_mobile from 'is-mobile';
import Padoru from 'util/padoru';
import { tutoV2Mobile, tutorialV2 } from 'util/tutorial';
import SignLine from 'components/Footer/SignLine';
import Header from 'components/Header';
import Tour from 'components/Tour';
import Maker2Mobile from './Maker2ViewMobile';
import Maker2Web from './Maker2Web';
import css from './styles';
import type {
  ChangeColorFunctionV2,
  ChangeGradiantFunctionV2,
  ChangePartFunctionV2,
} from 'interfaces/app';

interface Props {
  onChangeName: (n: string) => void;
  onChangeSkin: (color: string) => void;
  onChangePart: ChangePartFunctionV2;
  onChangeColor: ChangeColorFunctionV2;
  onChangeGradiant: ChangeGradiantFunctionV2;
  onRestorePadoru: (p: Padoru) => void;
  isFirstVisit: boolean;
  openTuto: boolean;
  openTutorial: (o: boolean) => void;
  padoru: Padoru;
  padoruName: string;
}

export default function MakerV2View(props: Props) {
  const clientWidth = document.documentElement.clientWidth;
  const isSm = useMediaQuery('(max-width:600px)') || clientWidth <= 600;
  //const isMd = useMediaQuery('(max-width:900px)') || clientWidth <= 900;
  const isMobile = is_mobile({ tablet: true });
  const isMobileView = isMobile && isSm;

  const {
    onChangeName,
    onChangeSkin,
    onChangePart,
    onChangeColor,
    onChangeGradiant,
    onRestorePadoru,
    isFirstVisit,
    openTuto,
    openTutorial,
    padoru,
    padoruName,
  } = props;

  return (
    <Box id="bg_maker_2" sx={css.mainBox}>
      <div id="modal-mode">
        <Tour
          open={openTuto}
          steps={isMobileView ? tutoV2Mobile : tutorialV2}
          isFirstVisit={isFirstVisit}
          onClose={() => openTutorial(false)}
        />
      </div>

      <Box
        id="maker_2_wrapper"
        display="flex"
        flexDirection="column"
        height="max-content"
        minHeight="100vh"
      >
        <Header />

        {isMobileView ? (
          <Maker2Mobile
            padoru={padoru}
            padoruName={padoruName}
            onChangeName={onChangeName}
            onChangeSkin={onChangeSkin}
            onChangePart={onChangePart}
            onChangeColor={onChangeColor}
            onChangeGradiant={onChangeGradiant}
            onRestorePadoru={onRestorePadoru}
            isFirstVisit={isFirstVisit}
            openTuto={openTuto}
            openTutorial={openTutorial}
          />
        ) : (
          <Maker2Web
            padoru={padoru}
            padoruName={padoruName}
            onChangeName={onChangeName}
            onChangeSkin={onChangeSkin}
            onChangePart={onChangePart}
            onChangeColor={onChangeColor}
            onChangeGradiant={onChangeGradiant}
            onRestorePadoru={onRestorePadoru}
            isFirstVisit={isFirstVisit}
            openTuto={openTuto}
            openTutorial={openTutorial}
          />
        )}

        <Box id="sign_line_wrapper" width={1} fontSize="0.75rem">
          <SignLine />
        </Box>
      </Box>
    </Box>
  );
}
