import invert from 'invert-color';
import { random } from 'lodash';
import type { GradiantMode } from 'interfaces/svg';

/** @deprecated */
export const slidesBySize = (): number => {
  const screenWidth = document.documentElement.clientWidth;
  const carrouselWidth = screenWidth - 16 * 6 - 40 * 2;
  const slides = Math.trunc(carrouselWidth / 60) - 1;
  return slides < 1 ? 1 : slides;
};

export const normalizeColor = (c = '#FFFFFF'): string => {
  const clean = `#${String(c).replace(/(#)+/, '').slice(0, 6)}`;
  return clean;
};
export const contrastColor = (c: string): string => invert(normalizeColor(c), true);

export const randomHexColor = (): string => {
  return (
    '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0').toUpperCase()
  );
};

const mds: GradiantMode['mode'][] = ['l', 'h', 'v', 'd1', 'd2'];
export const randomGradiant = (): GradiantMode => {
  return {
    blur: random(50),
    mode: mds[random(mds.length - 1)],
    tier: random(190),
  };
};

const now = () => {
  const d = new Date().toLocaleDateString('zh'); // yyyy/mm/dd
  const t = new Date().toLocaleTimeString('zh'); // hh:mm:ss
  return `${d}_${t}`.replace(/[:/]/gi, ''); // yyyymmdd_hhmmss
};
export const uuidName = () => `Padoru#${Date.now()}`;
export const filename = (pName: string, ext: string) => `${pName}_${now()}.${ext}`;

export const mapToObject = <K, T>(map: Map<K, T>) => Object.fromEntries(map);
export const objToMap = <K, T>(obj: object) =>
  new Map(Object.entries(obj)) as unknown as Map<K, T>;

export const delay = (ms: number) => new Promise(done => setTimeout(done, ms));

export const scrollTo = (
  id: string,
  position: ScrollIntoViewOptions['block'] = 'start',
) => {
  document.getElementById(id.replace('#', ''))?.scrollIntoView?.({ block: position });
};

enum CMPadoruNews {
  cm_news = 'v1_styles',
  cm_news_v2 = 'v2_gradient',
}
export const checkNews = (news: keyof typeof CMPadoruNews, view = false): boolean => {
  const viewed = localStorage.getItem(news) === 'true';
  return viewed || Boolean(localStorage.setItem(news, String(view)));
};
