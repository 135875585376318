import { memo, useEffect, useState } from 'react';
import { Box, CardMedia } from '@mui/material';
import svg2png from 'util/svg2png';

interface Props {
  svgUri: string;
  partId: string;
  isMobile: boolean;
}

function Capa(props: Props) {
  const { svgUri, partId, isMobile } = props;
  const [src, setSrc] = useState<string>('');

  useEffect(() => {
    if (!isMobile) {
      svg2png(svgUri, { height: 355, width: 355 }).then(setSrc);
    }
  }, [svgUri]);

  useEffect(() => {
    setSrc(svgUri);
  }, [svgUri]);

  return (
    <Box
      id={partId}
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
      }}
    >
      <CardMedia component="img" image={src} alt={partId} />
    </Box>
  );
}

const CapaMemo = memo(Capa, (prev, next) => prev.svgUri === next.svgUri);
export default CapaMemo;
