import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BlurMinIcon from '@mui/icons-material/Contrast';
import DeblurIcon from '@mui/icons-material/Deblur';
import NoneTwoColorsIcon from '@mui/icons-material/InvertColorsOffRounded';
import BlurMaxIcon from '@mui/icons-material/LensBlur';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import IconText from 'components/Labels/IconText';
import type { GradiantMode } from 'interfaces/svg';

interface Props {
  onChangeGradiant: (gradiant: GradiantMode) => void;
  gradiant: GradiantMode;
  disabled: boolean;
  floating: boolean;
  warningMode: boolean;
}

export default function BtnBlur(props: Readonly<Props>) {
  const { t } = useTranslation();
  const { onChangeGradiant, gradiant, disabled, floating, warningMode } = props;

  const [anchorElSeparate, setAnchorElSeparate] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleClickBlur = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSeparate(event.currentTarget);
  };

  const handleCloseBlur = () => {
    setAnchorElSeparate(null);
  };

  return (
    <Box id="btn_blur_wrapper">
      <IconButton onClick={handleClickBlur} disabled={disabled}>
        <DeblurIcon fontSize="large" />
      </IconButton>

      <Box id="popover_blur_wrapper">
        <Popover
          id="popover_blur"
          open={!!anchorElSeparate}
          anchorEl={anchorElSeparate}
          anchorReference={floating ? 'anchorPosition' : 'anchorEl'}
          anchorPosition={{
            top: document.documentElement.clientHeight / 2,
            left: document.documentElement.clientWidth / 10,
          }}
          onClose={handleCloseBlur}
          transitionDuration={100}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          sx={{ ml: 1.5 }}
          PaperProps={{ sx: { backgroundColor: grey[100] } }}
        >
          {warningMode && (
            <Box p={2}>
              <Alert severity="warning" variant="outlined">
                <IconText
                  icon={<NoneTwoColorsIcon />}
                  text={t('components.tools.blur.two_colors')}
                  variant="caption"
                />
              </Alert>
            </Box>
          )}
          <Stack spacing={2} direction="row" sx={{ p: 1.5, m: 0.5 }} alignItems="center">
            <BlurMaxIcon fontSize="medium" />
            <Slider
              orientation="horizontal"
              defaultValue={35}
              value={gradiant?.blur}
              valueLabelDisplay="off"
              disabled={disabled}
              track="inverted"
              min={25}
              max={50}
              step={5}
              marks
              onChange={(_e, v) => {
                onChangeGradiant({
                  blur: Number(v),
                  tier: gradiant.tier,
                  mode: gradiant.mode,
                });
              }}
              sx={{ minWidth: '12em' }}
            />
            <BlurMinIcon fontSize="medium" />
          </Stack>
        </Popover>
      </Box>
    </Box>
  );
}
