import i18n from 'i18next';
import { transform } from 'lodash';
import { initReactI18next } from 'react-i18next';
import { Lang, LANGS } from './langs';

interface ResourceI18n {
  [lang: string]: {
    translation: any;
  };
}

const resources = transform(
  LANGS,
  (r: ResourceI18n, v: Lang) =>
    (r[v.label] = { translation: require(`./locales/${v.label}.json`) }),
  {},
);

const config = {
  resources,
  lng: localStorage.getItem('language') || navigator.language.split('-')[0],
  fallbackLng: 'es',
  interpolation: { escapeValue: false },
};

i18n.use(initReactI18next).init(config);

export default i18n;
