import { useEffect, useMemo, useRef } from 'react';
import { Box, SxProps } from '@mui/material';

interface Props {
  image?: 'cards' | string;
  boxProps?: SxProps;
  imgProps?: object;
}

const DonateButton = (props: Props) => {
  const { image, boxProps = {}, imgProps = {} } = props;
  const buttonRef = useRef(null);
  const buttonId = useMemo(() => `btn_paypal_id_${Date.now()}`, []);
  const donateImage = 'https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif';
  const cardsImage = 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif';

  const srcImage = image === 'cards' ? cardsImage : image || donateImage;

  useEffect(() => {
    const button = (window as any).PayPal.Donation.Button({
      env: 'production',
      hosted_button_id: 'JG2XKM7KJJEW4',
      image: {
        src: srcImage,
        alt: 'Donate with PayPal',
        title: 'PayPal - The safer, easier way to pay online!',
      },
    });
    button.render(`#${(buttonRef as any)?.current.id}`);
  }, []);

  return (
    <Box
      ref={buttonRef}
      id={buttonId}
      sx={{
        width: 1,
        height: 1,
        '& img': imgProps,
        ...boxProps,
      }}
    />
  );
};

export default DonateButton;
