import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { SkinTones } from 'CONSTANTS';
import Padoru from 'util/padoru';
import Preview from 'components/Preview';
import StickyBox from 'components/StickyBox';
import SkinSelectorCard from './SkinSelectorCard';
import type { PadoruVersion } from 'interfaces/padoru';

interface Props {
  padoru: Padoru;
  padoruName: string;
  onChangeSkin: (color: string) => void;
  onChangeName: (name: string) => void;
  isMobile: boolean;
  isMd: boolean;
  v?: PadoruVersion;
}

export function WorkAreaStyled(props: Props) {
  const { t } = useTranslation();
  const { padoru, padoruName, isMobile, isMd, onChangeSkin, onChangeName, v } = props;
  const [editingName, setPadoruName] = useState<string>('');

  useEffect(() => {
    setPadoruName(padoruName);
  }, [padoruName]);

  const handleBlurName = () => {
    if (editingName) {
      onChangeName(editingName);
    } else {
      alert(t('components.work_area.error_name'));
    }
  };

  const stylesV2Sicky = {
    height: '30vh',
    width: '30vh',
    right: 0,
    //pt: 'calc(100vh - 21.5rem)',
    //pt: '25vh',
    pt: '8.7em',
    pointerEvents: 'none',
    //zIndex: 1000,
  };

  return (
    <Box sx={{ pt: 3 }}>
      <Card sx={{ mx: 2, mb: 3 }} id="padoru_name_card">
        <CardContent>
          <TextField
            fullWidth
            id="name_padoru_input"
            label={t('components.work_area.name_input')}
            variant="standard"
            onChange={(e: any) => setPadoruName(e.target.value)}
            onBlur={handleBlurName}
            value={editingName}
            error={!editingName}
          />
        </CardContent>
      </Card>

      {isMobile && isMd ? (
        <StickyBox zindex={1000} stickySx={v === '2' ? stylesV2Sicky : null}>
          <Card sx={{ mx: 2, p: 1 }}>
            <Preview padoru={padoru} padoruName={padoruName} isMobile={isMobile} v={v} />
          </Card>
        </StickyBox>
      ) : (
        <Card sx={{ mx: 2, p: 1 }}>
          <Preview padoru={padoru} padoruName={padoruName} isMobile={false} v={v} />
        </Card>
      )}

      <SkinSelectorCard
        sx={{ mt: 3, mx: 2 }}
        skin={padoru.parts.get('head')?.colors[0] ?? SkinTones[0]}
        onChangeSkin={onChangeSkin}
      />
    </Box>
  );
}
