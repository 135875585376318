import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { grey } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SkinTones } from 'CONSTANTS';
import Padoru from 'util/padoru';
import TabIcon from 'components/Form/TabIcon';
import SelectorSectionMobile from 'components/SelectorCard/SelectorSectionMobile';
import SkinSelectorCard from 'components/WorkArea/SkinSelectorCard';
import type { ChangePartFunctionV2 } from 'interfaces/app';
import type { KeyPart } from 'interfaces/padoru';

const SORTED_TABS: KeyPart[] = [
  'head',
  'hair_back',
  'hair_middle',
  'hair_front',
  'ponytail',
  'eyes_pupil',
  'blush',
  'mouth',
  'ears',
  'hat',
  'body',
  'neck',
  'bag',
  'glasses',
  'mask',
  'head_acc',
  'wings',
];

interface Props {
  partTab: KeyPart;
  handlerPartTab: (s: KeyPart) => void;
  onChangePart: ChangePartFunctionV2;
  onChangeSkin: (color: string) => void;
  padoru: Padoru;
}

function FormV2Mobile(props: Props) {
  const { t } = useTranslation();

  const clientHeight = document.documentElement.clientHeight;
  const isShort = useMediaQuery('(max-height: 620px)') || clientHeight <= 620;

  const { partTab, handlerPartTab, onChangePart, onChangeSkin, padoru } = props;

  return (
    <Box id="maker_2_parts_wrapper">
      <Box id="tabs_wrapper">
        <Tabs
          id="form_v2_tabs"
          value={partTab}
          onChange={(_e, newValue) => handlerPartTab(newValue)}
          variant="scrollable"
          indicatorColor="secondary"
          scrollButtons={true}
          visibleScrollbar={true}
          sx={{ color: grey[500] }}
        >
          {SORTED_TABS.map(part => (
            <Tab
              key={part}
              id={`tab_icon_${part}`}
              icon={<TabIcon part={part} size="3vh" />}
              label={t(`schema.parts.${part}`)}
              value={part}
              wrapped
              sx={{ color: 'inherit', p: 0.5 }}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        id="grid-container"
        sx={{
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          maxHeight: '22vh',
          height: 'max-content',
          px: 1.1,
          pb: 1.5,
          pt: 0.3,
          position: 'relative',
        }}
      >
        <Box
          id="grid_wrapper_base_2_pieces"
          position="relative"
          sx={{
            background: 'white',
            height: `calc(8px + ${!isShort || partTab === 'head' ? '22vh' : '11vh'})`,
            width: 1,
          }}
        >
          <SkinSelectorCard
            isMobile
            sx={{
              height: '23vh',
              width: 1,
              py: 1,
              position: 'absolute',
              backgroundColor: 'white',
              zIndex: Number(partTab === 'head'),
              opacity: Number(partTab === 'head'),
              pointerEvents: partTab === 'head' ? 'all' : 'none',
            }}
            onChangeSkin={onChangeSkin}
            skin={padoru.parts.get('head')?.colors?.[0] ?? SkinTones[0]}
          />
          {SORTED_TABS.slice(1).map(tab => (
            <SelectorSectionMobile
              key={tab}
              keyPart={tab}
              isShort={isShort}
              onChangePart={onChangePart}
              uuid={padoru.parts.get(tab)?.uuid}
              show={tab === partTab}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default FormV2Mobile;
