import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ROOT } from 'paths';

export default function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <Box id="not_found_container" color="white" textAlign="center">
      <Stack id="not_found" justifyContent="space-around" spacing={3.5} p={3}>
        <Typography variant="h1">
          <b>404</b>
        </Typography>

        <Typography align="center" variant="h3">
          Page Not Found!
        </Typography>

        <Box>
          <Button variant="contained" color="secondary" sx={{ p: 2 }}>
            <Link href={ROOT} component="a" underline="none" color={'white'}>
              {t('pages.thanks.button_home')}
            </Link>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
