import { memo } from 'react';
import Box from '@mui/material/Box';
import { blue, deepPurple } from '@mui/material/colors';
import Boutique from 'boutique';
import BtnImg from 'components/Buttons/BtnImg';
import { CustomSvg } from 'util/svg';
import type { ChangePartFunctionV2 } from 'interfaces/app';
import type { KeyPart } from 'interfaces/padoru';

interface Props {
  uuid?: string;
  keyPart: KeyPart;
  show: boolean;
  onChangePart: ChangePartFunctionV2;
}

function SelectorSection(props: Props) {
  const { show, keyPart, uuid, onChangePart } = props;

  const defaultColors = [blue[300], blue[600], deepPurple[200], deepPurple[400]];

  return (
    <Box
      id={`tab_${keyPart}`}
      sx={{
        position: 'relative',
        height: 'max-content',
        flexWrap: 'wrap',
        display: show ? 'flex' : 'none',
        backgroundColor: 'white',
        pb: 3,
      }}
    >
      {Boutique.getArray(keyPart, '2').map(data => (
        <Box
          p={data.uuid === uuid ? 0.2 : 0.5}
          display="inline-block"
          key={data.uuid}
          data-version="v2"
          data-uuid={data.uuid}
        >
          <BtnImg
            uuid={data.uuid}
            selected={data.uuid === uuid}
            isMobile={false}
            onClick={onChangePart(keyPart)}
            svgUri={new CustomSvg(data, defaultColors, true).dataUrl()}
          />
        </Box>
      ))}
    </Box>
  );
}

const SelectorSectionMemo = memo(SelectorSection, (prev, next) => {
  const sameTab = prev.show === next.show;
  const samePiece = prev.uuid === next.uuid;
  const render = !sameTab || (sameTab && !samePiece);
  return !render;
});

/**
 * return true = no render
 * return false = render
 */

export default SelectorSectionMemo;
//export default SelectorSection;
