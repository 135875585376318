import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LangIcon from '@mui/icons-material/GTranslateRounded';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { LANGS } from 'i18n/langs';
import IconText from 'components/Labels/IconText';
import SimpleModal from 'components/Modals';
import OtherLanguageModal from 'components/Modals/OtherLanguage';

interface Props {
  isMd: boolean;
}

function LangStyled(props: Props) {
  const { t, i18n } = useTranslation();
  const { isMd } = props;
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [openModalLng, setOpenModalLng] = useState<boolean>(false);
  const selectedLang = i18n.language;

  function onChangeLang(lang: string) {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box p={1} color="inherit">
      <SimpleModal open={openModalLng} onClose={() => setOpenModalLng(false)} btnClose>
        <OtherLanguageModal />
      </SimpleModal>

      {isMd ? (
        <Box>
          <IconButton onClick={handleOpenNavMenu} color="inherit">
            <LangIcon color="inherit" />
          </IconButton>
          <Menu
            id="menu_lang_mobile"
            keepMounted
            anchorEl={anchorElNav}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {LANGS.map(({ label, name }, k) => (
              <MenuItem
                key={k}
                onClick={() => {
                  handleCloseNavMenu();
                  onChangeLang(label);
                }}
              >
                <Typography>{name}</Typography>
              </MenuItem>
            ))}
            <MenuItem onClick={() => setOpenModalLng(true)}>
              <Typography>{t('components.lang.other')}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box>
          <IconText icon={<LangIcon />} variant="h6" text={t('components.lang.title')} />
          <Box id="menu_lang_mobile" py={1} color="white">
            {LANGS.map(({ label, name }, k) => (
              <Tooltip title={name} key={k}>
                <Chip
                  color="default"
                  sx={{ mx: 0.3, color: 'white' }}
                  variant={selectedLang !== label ? 'filled' : 'outlined'}
                  label={label.toUpperCase()}
                  onClick={() => onChangeLang(label)}
                />
              </Tooltip>
            ))}
            <Chip
              color="default"
              sx={{ mx: 0.3, color: 'white' }}
              variant={!openModalLng ? 'filled' : 'outlined'}
              label={t('components.lang.other')}
              onClick={() => setOpenModalLng(true)}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default LangStyled;
