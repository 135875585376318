import type { SxProps } from '@mui/material';
import bgAldea from 'assets/imgs/bg-mobile-night.jpeg';

const mainBox: SxProps = {
  height: '100%',
  minHeight: '99.5vh',
  backgroundImage: `url(${bgAldea})`,
  backgroundPosition: 'top',
  backgroundRepeat: 'repeat',
  backgroundSize: 'auto 100%',
  backgroundAttachment: 'fixed',
};
const mainBlur: SxProps = {
  backgroundColor: 'rgb(31 37 74 / 54%)',
  position: 'absolute',
  height: 1,
  width: '100%',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const css = { mainBox, mainBlur };
export default css;
