import type { SxProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

const IconStyle: SxProps = { height: '1.1em', width: '1.1em', mx: 0.2 };
const SignStyle: SxProps = {
  color: 'white',
  backgroundColor: 'black',
  p: 0.7,
  fontWeight: 'bold',
};

function SignLine() {
  return (
    <Box id="sign_line" display="flex" justifyContent="space-between" sx={SignStyle}>
      <Grid container spacing={1} justifyContent="space-between" direction="row">
        <Grid
          item
          container
          xs={12}
          sm={4}
          alignItems="center"
          justifyContent={{ xs: 'center', sm: 'flex-start' }}
        >
          <Box>Made with &#10084; by</Box>
          <Box>
            <Avatar sx={IconStyle} src="https://imgur.com/YBC1K87.png" alt="CM_logo" />
          </Box>
          <Box>
            <Link href="mailto:crystal.marino345@gmail.com" color="inherit">
              CrystalMoon
            </Link>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          container
          alignItems="center"
          justifyContent="center"
          display={{ xs: 'none', sm: 'flex' }}
        >
          <Box>{`2022, v${process.env.REACT_APP_VERSION}`}</Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          container
          alignItems="center"
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
          display={{ xs: 'none', sm: 'flex' }}
        >
          <Box>Powered by</Box>
          <Box>
            <Tooltip title="ReactJS" placement="top">
              <Avatar
                sx={IconStyle}
                variant="square"
                src="https://imgur.com/SkcRcfF.png"
                alt="ReactJS"
              />
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title="Typescript" placement="top">
              <Avatar
                sx={IconStyle}
                variant="square"
                src="https://imgur.com/3bM70A4.png"
                alt="Typescript"
              />
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title="Material-UI" placement="top">
              <Avatar
                sx={IconStyle}
                variant="square"
                src="https://imgur.com/2su7ahA.png"
                alt="Material-UI"
              />
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SignLine;
