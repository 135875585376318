import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { grey } from '@mui/material/colors';
import Padoru from 'util/padoru';
import SelectorSection from 'components/SelectorCard/SelectotCardV2';
import TabIcon from './TabIcon';
import type { ChangePartFunctionV2 } from 'interfaces/app';
import { KeyPart } from 'interfaces/padoru';

const SORTED_TABS: KeyPart[] = [
  'hair_back',
  'hair_middle',
  'hair_front',
  'ponytail',
  'eyes_pupil',
  'blush',
  'mouth',
  'ears',
  'hat',
  'body',
  'neck',
  'bag',
  'glasses',
  'mask',
  'head_acc',
  'wings',
];

interface Props {
  partTab: KeyPart;
  handlerPartTab: (s: KeyPart) => void;
  onChangePart: ChangePartFunctionV2;
  padoru: Padoru;
  isMobile?: boolean;
  isSm?: boolean;
}

function FormV2(props: Props) {
  const { t } = useTranslation();
  const { partTab, handlerPartTab, onChangePart, padoru, isMobile, isSm } = props;

  return (
    <Box height="calc(100% - 24px)">
      <Paper id="derecha_container" sx={{ height: 1, position: 'relative', mt: 3 }}>
        <Box
          id="tabs_y_contents"
          sx={{
            height: 'calc(100% - 32px)',
            p: 2,
            boxSizing: 'border-box',
            overflow: 'hidden',
            pb: isSm ? '10em' : 2,
          }}
        >
          <Tabs
            id="form_v2_tabs"
            value={partTab}
            onChange={(_e, newValue) => handlerPartTab(newValue)}
            variant="scrollable"
            indicatorColor="secondary"
            scrollButtons={true}
            visibleScrollbar={isMobile}
            sx={{ color: grey[500] }}
          >
            {SORTED_TABS.map(part => (
              <Tab
                key={part}
                id={`tab_icon_${part}`}
                icon={<TabIcon part={part} size={36} />}
                label={t(`schema.parts.${part}`)}
                value={part}
                wrapped
                sx={{ color: 'inherit' }}
              />
            ))}
          </Tabs>
          <Box
            id="contents_tabs_btns"
            sx={{
              position: 'relative',
              height: 'inherit',
              overflow: 'auto',
              boxSizing: 'border-box',
              //minHeight: 460, // para mobile
              minHeight: '60vh',
            }}
          >
            <Box
              id="contents_height_btns"
              sx={{
                position: 'absolute',
                overflow: 'hidden',
                backgroundColor: 'white',
                //height: customHeight + 8 * 3.5,
                height: 'max-content',
                //minHeight: 460 + (8 * 3.5),
                minHeight: 'max-content',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                //pl: 6.5,
                pl: isSm ? 0 : 6.5,
                pt: 1,
              }}
            >
              {SORTED_TABS.map(part => (
                <SelectorSection
                  key={part}
                  keyPart={part}
                  uuid={padoru.parts.get(part)?.uuid}
                  onChangePart={onChangePart}
                  show={partTab === part}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default FormV2;
