import { memo, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { AfectedSkinParts } from 'CONSTANTS';
import Boutique from 'boutique';
import { map, pull } from 'lodash';
import ColorPicker from 'components/ColorPicker';
import BtnImg from '../Buttons/BtnImg';
import Carrousel from './CarrouselV2';
import { CustomSvg } from 'util/svg';
import type {
  ChangeColorFunction,
  ChangePartFunction,
  SectionPart,
  SelectedsUuid,
} from 'interfaces/app';
import type { KeyPart } from 'interfaces/padoru';

interface Prop {
  id: string;
  name: string;
  colors: string;
  skinColors: string;
  carrousels: SectionPart[];
  editableColor: boolean;
  onChangePart: ChangePartFunction;
  onChangeColor: ChangeColorFunction;
  isMobile: boolean;
  forceReset: number;
  initUuids: SelectedsUuid;
}

function SelectorCardStyled(props: Prop) {
  const {
    id,
    name,
    colors: stringColors,
    skinColors,
    carrousels,
    editableColor,
    onChangePart,
    onChangeColor,
    isMobile,
    initUuids,
  } = props;

  const [uids, setUids] = useState<SelectedsUuid>({});

  useEffect(() => {
    setUids(initUuids);
  }, [initUuids]);

  const colors = stringColors.split(',');
  const padoruSkinColors = skinColors.split(',');
  const mapper: Map<number, (color: string) => void> = useMemo(() => {
    return new Map(
      colors.map((_c, i) => [
        i,
        onChangeColor(pull(map(carrousels, 'key'), ...AfectedSkinParts), i),
      ]),
    );
  }, [carrousels, colors]);

  const getColors = (key: KeyPart) => {
    return AfectedSkinParts.includes(key) ? padoruSkinColors : colors;
  };

  const handlerChangePart = (func: (uuid: string) => KeyPart) => (id: string) => {
    const pkey = func(id);
    const u = { ...uids };
    u[pkey] = id;
    setUids(u);
  };

  return (
    <Card sx={{ mx: 2, my: 3 }} id={id}>
      <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Typography variant="h5">{name}</Typography>
          {editableColor && (
            <ButtonGroup id={`${id}_colors`} sx={{ overflow: 'auto' }}>
              {colors.map((color, num) => (
                <Button sx={{ bgcolor: color }} key={num}>
                  <ColorPicker
                    id={`id_${name}_${num}_${color}`}
                    size={36}
                    color={color}
                    noBg
                    onClose={mapper.get(num)!}
                  />
                </Button>
              ))}
            </ButtonGroup>
          )}
        </Box>
      </CardContent>
      <CardContent>
        {carrousels?.map(carrusel => (
          <Carrousel
            id={`carrousel_${carrusel.key}`}
            key={carrusel.key}
            name={carrusel.name}
          >
            {Boutique.getArray(carrusel.key).map(data => (
              <BtnImg
                key={data.uuid}
                uuid={data.uuid}
                selected={data.uuid === uids[carrusel.key]}
                isMobile={isMobile}
                onClick={handlerChangePart(
                  onChangePart(carrusel.key, getColors(carrusel.key)),
                )}
                svgUri={new CustomSvg(data, getColors(carrusel.key), true).dataUrl()}
              />
            ))}
          </Carrousel>
        ))}
      </CardContent>
    </Card>
  );
}

export const SelectorCard = memo(SelectorCardStyled, (prev: Prop, next: Prop) => {
  const sameColors = prev.colors === next.colors;
  const sameSkin = prev.skinColors === next.skinColors;
  const sameLang = prev.name === next.name;
  const forceReset = prev.forceReset === next.forceReset;
  return sameColors && sameSkin && sameLang && forceReset;
});
