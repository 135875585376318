import { useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import ContrastRoundedIcon from '@mui/icons-material/ContrastRounded';
import NoneTwoColorsIcon from '@mui/icons-material/InvertColorsOffRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { grey } from '@mui/material/colors';
import ToggleIconButton from './ToggleIconButton';
import type { GradiantMode } from 'interfaces/svg';

interface Props {
  onChangeGradiant: (gradiant: GradiantMode) => void;
  gradiant: GradiantMode;
  disabled: boolean;
  floating: boolean;
}

export default function BtnDirection(props: Readonly<Props>) {
  const { onChangeGradiant, gradiant, disabled, floating } = props;

  const [anchorElDirection, setAnchorElDirection] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleClickDirection = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDirection(!anchorElDirection ? event.currentTarget : null);
  };

  const handleCloseDirection = () => {
    setAnchorElDirection(null);
  };

  const selectIcon = (m?: GradiantMode['mode']) => {
    switch (m) {
      case 'l':
        return <NoneTwoColorsIcon fontSize="large" />;
      case 'v':
        return (
          <ContrastRoundedIcon sx={{ transform: 'rotate(180deg)' }} fontSize="large" />
        );
      case 'h':
        return (
          <ContrastRoundedIcon sx={{ transform: 'rotate(-90deg)' }} fontSize="large" />
        );
      case 'd1':
        return (
          <ContrastRoundedIcon sx={{ transform: 'rotate(45deg)' }} fontSize="large" />
        );
      case 'd2':
      default:
        return (
          <ContrastRoundedIcon sx={{ transform: 'rotate(135deg)' }} fontSize="large" />
        );
    }
  };

  return (
    <Box id="btn_direction_wrapper">
      <IconButton onClick={handleClickDirection} disabled={disabled}>
        {selectIcon(gradiant?.mode)}
      </IconButton>

      <Box id="popober_direction">
        <Popover
          open={!!anchorElDirection}
          anchorEl={anchorElDirection}
          anchorReference={floating ? 'anchorPosition' : 'anchorEl'}
          anchorPosition={{
            top: document.documentElement.clientHeight / 2,
            left: document.documentElement.clientWidth / 10,
          }}
          onClose={handleCloseDirection}
          transitionDuration={100}
          id="popover_diretion"
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          sx={{ ml: 1.5 }}
          PaperProps={{ sx: { p: 1, backgroundColor: grey[100] } }}
        >
          <ToggleIconButton
            id="primer_section"
            direction="row"
            value={gradiant.mode ?? 'd1'}
            onChange={v => {
              onChangeGradiant({
                blur: gradiant.blur,
                tier: gradiant.tier,
                mode: v as GradiantMode['mode'],
              });
            }}
          >
            <IconButton key={'l'}>
              <CircleIcon />
            </IconButton>
            <IconButton key={'v'}>
              <ContrastRoundedIcon sx={{ transform: 'rotate(180deg)' }} />
            </IconButton>
            <IconButton key={'d2'}>
              <ContrastRoundedIcon sx={{ transform: 'rotate(135deg)' }} />
            </IconButton>
            <IconButton key={'h'}>
              <ContrastRoundedIcon sx={{ transform: 'rotate(-90deg)' }} />
            </IconButton>
            <IconButton key={'d1'}>
              <ContrastRoundedIcon sx={{ transform: 'rotate(45deg)' }} />
            </IconButton>
          </ToggleIconButton>
        </Popover>
      </Box>
    </Box>
  );
}
