import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Stack, { type StackProps } from '@mui/material/Stack';

interface Props {
  id: string;
  children: JSX.Element[];
  value: string | number;
  onChange: (v: string | number) => void;
  direction?: StackProps['direction'];
  square?: boolean;
  sx?: SxProps;
}

export default function ToggleIconButton(props: Props) {
  const { id, children, direction, square, value, onChange, sx = {} } = props;

  return (
    <Stack id={id} direction={direction}>
      {children.map(child => (
        <Box
          key={child.key}
          sx={{
            ...sx,
            border: child.key === value ? '2px solid' : undefined,
            borderRadius: square ? 0 : '50%',
            m: '2px',
          }}
          onClick={() => onChange(child.key as typeof value)}
        >
          {child}
        </Box>
      ))}
    </Stack>
  );
}
