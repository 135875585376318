import Boutique from 'boutique';
import { defaults, random } from 'lodash';
import {
  AfectedSkinParts,
  DefaultColorsV2,
  DefaultOCParts,
  InxPart,
  PadoruThumbnail,
  SkinTones,
  ZeroParts,
} from '../CONSTANTS';
import {
  mapToObject,
  objToMap,
  randomGradiant,
  randomHexColor,
  uuidName,
} from './helpers';
import { makeThumbnail } from './maker';
import { CustomSvg, CustomSvgV2 } from './svg';
import type {
  PadoruActualStorage,
  PadoruStorage,
  PadoruUri,
  PadoruVersion,
  Part,
  PartMap,
} from 'interfaces/padoru';

class Padoru {
  id: string;
  thumbnail?: string;
  name: string;
  parts: PartMap;
  _v: PadoruVersion;
  static lastet: PadoruVersion = '2';

  // crear un padoru random en v2, cambiar a /v1 y ver como se ve,
  // si se vé feo, revisar la toolbar1, zero, oc y random necesitarian la v para crear el padoru
  constructor(name: string, parts: PartMap, v = Padoru.lastet) {
    this.id = String(Date.now());
    this.name = name || uuidName();
    this._v = v;
    this.parts = parts;
  }

  static async generateIcon(p: Padoru): Promise<string> {
    p.thumbnail = await makeThumbnail();
    return String(p.thumbnail);
  }

  static toJson(p: Padoru): PadoruActualStorage {
    const partsObj = mapToObject(p.parts);
    return {
      id: p.id,
      v: p._v,
      name: p.name,
      parts: partsObj,
    };
  }

  static toPadoru(storage: PadoruStorage): Padoru {
    const partsMap: PartMap = objToMap(storage.parts);
    const p = new Padoru(storage.name, partsMap, storage.v ?? '1');
    p.id = storage.id;
    p.thumbnail = storage.thumbnail;
    return p;
  }

  static async toStorage(p: Padoru): Promise<PadoruStorage> {
    const thumbnail = await Padoru.generateIcon(p);
    const pJson = Padoru.toJson(p);
    return { ...pJson, thumbnail };
  }

  static toPadoruUri(padoru: Padoru, v?: PadoruVersion): PadoruUri {
    const parts = Array.from(padoru.parts.values())
      .map((p: Part) => {
        try {
          const jsvg = Boutique.get(p.key, padoru._v).get(p.uuid);
          const zInx = (InxPart[p.key] * 10 + (jsvg.zInx || 0)) * (jsvg.multiply ?? 1);
          const uri =
            v === '2'
              ? new CustomSvgV2(jsvg, p).dataUrl()
              : new CustomSvg(jsvg, p.colors).dataUrl();
          return { zInx, uri, uuid: p.uuid };
        } catch (e) {
          console.error(e);
          return false as any;
        }
      })
      .filter(Boolean)
      .sort((a, b) => a.zInx - b.zInx);

    return { id: padoru.id, name: padoru.name, parts: parts };
  }

  static toV2(padoruV1: Padoru) {
    const partsV2: PartMap = new Map();
    const skinColor = padoruV1.parts.get('head')?.colors ?? [SkinTones[0]];
    Array.from(padoruV1.parts.values()).forEach(p => {
      const p2 = { ...p };
      switch (p2.key) {
        case 'hair_back':
        case 'hair_middle':
        case 'hair_front':
          p2.colors = [p.colors[0], p.colors[0], p.colors[1]];
          break;
        //case 'hair_front': // si ningun flequillo amerita color2, sacar color 2 asi:
        //  p2.colors = [p.colors[0], p.colors[1]];
        case 'eyes_iris':
        case 'bag':
          p2.colors = [p.colors[0]];
          break;
        case 'eyes_pupil':
        case 'glasses':
          p2.colors = [p.colors[0], p.colors[0]];
          break;
        case 'mouth':
          p2.colors = [p.colors[1]];
          break;
        case 'neck':
          p2.colors = [p.colors[2]];
          break;
        case 'ears':
          p2.colors = skinColor;
          break;
        case 'blush':
          p2.colors = [];
          break;
        case 'head_acc':
        case 'body':
        case 'hat':
          p2.colors = [p.colors[0], p.colors[1]];
          break;
        case 'wings':
          p2.colors = [p.colors[1], p.colors[2]];
          break;
      }
      p2.colors = defaults(DefaultColorsV2.get(p.key) ?? [], p2.colors);

      partsV2.set(p2.key, p2);
    });

    return new Padoru(padoruV1.name, partsV2, '2');
  }

  static toV(p: Padoru, v: PadoruVersion) {
    if (v === p._v) return p;
    if (v === '2') return Padoru.toV2(p);
    return p;
  }

  static OC(v: PadoruVersion): PadoruStorage {
    const p = new Padoru('Padoru', new Map(DefaultOCParts), '1');
    return {
      ...Padoru.toJson(v === '2' ? Padoru.toV2(p) : p),
      thumbnail: PadoruThumbnail,
    };
  }

  static zero(v: PadoruVersion): Padoru {
    const p = new Padoru(uuidName(), new Map(ZeroParts), '1');
    return v === '2' ? Padoru.toV2(p) : p;
  }

  static random(v: PadoruVersion): Padoru {
    const padoruSkin = SkinTones[random(4)];
    const parts: PartMap = new Map(
      Array.from(ZeroParts.values()).map((p: Part) => {
        const rp = Object.assign({}, p);
        const arr = Boutique.getArray(rp.key, Padoru.lastet);
        const part = arr[random(arr.length - 1)];
        rp.uuid = part.uuid;
        if (AfectedSkinParts.includes(rp.key)) {
          rp.colors = [padoruSkin];
        } else {
          rp.colors = p.colors.map(() => randomHexColor());
          rp.gradiant = randomGradiant();
        }
        return [rp.key, rp];
      }),
    );
    const p = new Padoru(uuidName(), parts, '1');
    return v === '2' ? Padoru.toV2(p) : p;
  }
}

export default Padoru;
