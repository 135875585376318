import { useTranslation } from 'react-i18next';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  OKIcon,
  OKShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
} from 'react-share';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const WEBSITE_URL = 'https://padoru-maker.online';
const OG_IMAGE = 'https://padoru-maker.online/logo512.png';

function ShareWebSiteStyled() {
  const { t } = useTranslation();
  const title = t('components.share.web_title'),
    description = t('components.share.web_text'),
    tags = ['padoru', 'maker', 'free', 'chrismas', `${new Date().getFullYear()}`];

  return (
    <div>
      <Typography variant="h6" component="h2">
        {t('components.modals.share_web.title')}
      </Typography>
      <Typography my={2} sx={{ whiteSpace: 'pre-line' }}>
        {t('components.modals.share_web.content')}
      </Typography>
      <Box>
        <ButtonGroup variant="text">
          <IconButton>
            <FacebookShareButton
              url={WEBSITE_URL}
              hashtag={`#${tags.join(' #')}`}
              quote={description}
            >
              <FacebookIcon round />
            </FacebookShareButton>
          </IconButton>

          <IconButton>
            <TwitterShareButton
              url={WEBSITE_URL}
              title={title}
              hashtags={tags}
              via={WEBSITE_URL}
            >
              <TwitterIcon round />
            </TwitterShareButton>
          </IconButton>

          <IconButton>
            <WhatsappShareButton url={WEBSITE_URL} title={description}>
              <WhatsappIcon round />
            </WhatsappShareButton>
          </IconButton>

          <IconButton>
            <TelegramShareButton url={WEBSITE_URL} title={title}>
              <TelegramIcon round />
            </TelegramShareButton>
          </IconButton>

          <IconButton>
            <LinkedinShareButton
              url={WEBSITE_URL}
              title={title}
              summary={description}
              source={title}
            >
              <LinkedinIcon round />
            </LinkedinShareButton>
          </IconButton>

          <IconButton>
            <VKShareButton url={WEBSITE_URL} title={title} image={OG_IMAGE}>
              <VKIcon round />
            </VKShareButton>
          </IconButton>

          <IconButton>
            <OKShareButton
              image={OG_IMAGE}
              url={WEBSITE_URL}
              title={title}
              description={description}
            >
              <OKIcon round />
            </OKShareButton>
          </IconButton>

          <IconButton>
            <RedditShareButton url={WEBSITE_URL} title={title}>
              <RedditIcon round />
            </RedditShareButton>
          </IconButton>

          <IconButton>
            <WorkplaceShareButton
              url={WEBSITE_URL}
              quote={description}
              hashtag={`#${tags.join(' #')}`}
            >
              <WorkplaceIcon round />
            </WorkplaceShareButton>
          </IconButton>

          <IconButton>
            <TumblrShareButton
              url={WEBSITE_URL}
              title={title}
              tags={tags}
              caption={description}
            >
              <TumblrIcon round />
            </TumblrShareButton>
          </IconButton>

          <IconButton>
            <PinterestShareButton
              media={OG_IMAGE}
              description={description}
              url={WEBSITE_URL}
            >
              <PinterestIcon round />
            </PinterestShareButton>
          </IconButton>

          <IconButton>
            <EmailShareButton url={WEBSITE_URL} subject={title} body={description}>
              <EmailIcon round />
            </EmailShareButton>
          </IconButton>

          <IconButton>
            <LineShareButton url={WEBSITE_URL} title={title}>
              <LineIcon round />
            </LineShareButton>
          </IconButton>
        </ButtonGroup>
      </Box>
    </div>
  );
}

export default ShareWebSiteStyled;
