import { Box, Grid, useMediaQuery } from '@mui/material';
import is_mobile from 'is-mobile';
import Padoru from 'util/padoru';
import { tutorial } from 'util/tutorial';
import Form from 'components/Form';
import StorageBar from 'components/ToolBar/StorageBar';
import ToolBar from 'components/ToolBar/ToolBar';
import Tour from 'components/Tour';
import WorkArea from 'components/WorkArea';
import type {
  ChangeColorFunction,
  ChangePartFunction,
  ISchema,
  SelectedsUuid,
} from 'interfaces/app';

interface Props {
  isFirstVisit: boolean;
  padoru: Padoru;
  padoruName: string;
  onChangePart: ChangePartFunction;
  onChangeColor: ChangeColorFunction;
  onChangeSkin: (color: string) => void;
  onChangeName: (n: string) => void;
  onRestorePadoru: (p: Padoru) => void;
  Schema: ISchema;
  openTuto: boolean;
  openTutorial: (o: boolean) => void;
  forceReset: number;
  initUuids: SelectedsUuid;
}

const MainView = (props: Props): JSX.Element => {
  const isMobile = is_mobile({ tablet: true });
  const isMd = useMediaQuery('(max-width:900px)');
  const {
    isFirstVisit,
    padoru,
    padoruName,
    onChangeColor,
    onChangePart,
    onRestorePadoru,
    onChangeSkin,
    onChangeName,
    Schema,
    openTuto,
    openTutorial,
    forceReset,
    initUuids,
  } = props;

  return (
    <div>
      <Tour
        open={openTuto}
        isFirstVisit={isFirstVisit}
        steps={tutorial}
        onClose={() => openTutorial(false)}
      />

      <div id="modal-mode">
        <StorageBar isMobile={isMobile} onRestorePadoru={onRestorePadoru} />
        <ToolBar
          isMobile={isMobile}
          v2={false}
          openTutorial={openTutorial}
          onNewPadoru={onRestorePadoru}
        />
      </div>

      <Grid
        container
        id="welcome_div"
        justifyContent="space-around"
        sx={{ mt: { xs: 1, sm: '4em' }, mb: '4em', overflow: 'hidden' }}
      >
        <Grid item xs={12} md={5}>
          <WorkArea
            padoru={padoru}
            padoruName={padoruName}
            onChangeSkin={onChangeSkin}
            onChangeName={onChangeName}
            isMobile={isMobile}
            isMd={isMd}
          />
        </Grid>
        <Grid item xs={12} md={7} overflow="auto" position="relative">
          <Box height={1} width={1} position={isMd ? 'relative' : 'absolute'}>
            <Form
              padoru={padoru}
              initUuids={initUuids}
              onChangePart={onChangePart}
              onChangeColor={onChangeColor}
              Schema={Schema}
              isMobile={isMobile}
              forceReset={forceReset}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default MainView;
