import Carousel from '@itseasy21/react-elastic-carousel';
import { memo } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowNextIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { purple } from '@mui/material/colors';

interface Props {
  id: string;
  name?: string;
  children: any;
}

function CarrouselV3Styled(props: Props) {
  const { id, name = '', children } = props;

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 150, itemsToShow: 2, itemsToScroll: 1 },
    { width: 228, itemsToShow: 3, itemsToScroll: 2 },
    { width: 306, itemsToShow: 4, itemsToScroll: 3 },
    { width: 460, itemsToShow: 5, itemsToScroll: 4 },
    { width: 550, itemsToShow: 5, itemsToScroll: 4 },
  ];

  return (
    <Box id={id}>
      <Typography variant="subtitle2">{name}</Typography>
      <Carousel
        pagination={false}
        isRTL={false}
        enableTilt
        breakPoints={breakPoints}
        renderArrow={({ type, onClick, isEdge }) => (
          <IconButton sx={{ color: purple[600] }} onClick={onClick} disabled={isEdge}>
            {type === 'PREV' ? <ArrowBackIcon /> : <ArrowNextIcon />}
          </IconButton>
        )}
      >
        {children}
      </Carousel>
    </Box>
  );
}

const CarrouselMemo = memo(CarrouselV3Styled);
export default CarrouselMemo;
