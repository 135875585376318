import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ROOT } from 'paths';

export default function ThanksPage() {
  const { t } = useTranslation();
  return (
    <Box id="thanks_container" color="white" textAlign="center">
      <Grid container id="thanks_grid" justifyContent="space-around" spacing={3.5} p={3}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3">
            {t('pages.thanks.title')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography align="justify">{t('pages.thanks.p1')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography align="justify">{t('pages.thanks.p2')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography align="left" component="p" m="revert" pr={2} variant="subtitle2">
            {t('pages.thanks.p3')}
          </Typography>
        </Grid>

        <Grid item xs={12} p={3} id="btn_home">
          <Button variant="contained" color="secondary" sx={{ p: 2 }}>
            <Link href={ROOT} component="a" underline="none" color={'white'}>
              {t('pages.thanks.button_home')}
            </Link>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
