import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import type { MuiBasicColor } from 'interfaces/app';

interface Props {
  title: string;
  content?: string;
  color?: MuiBasicColor;
  value?: number;
  size?: number;
}

function ProgressModalStyled(props: Props) {
  const { title, content, color, value, size } = props;
  return (
    <div>
      <Typography variant="h6" component="h2">
        {title}
      </Typography>
      <Typography my={2}>{content}</Typography>
      <Box textAlign="center" mt={2.7} p={1.5}>
        <CircularProgress color={color} value={value} size={size} />
      </Box>
    </div>
  );
}

export default ProgressModalStyled;
