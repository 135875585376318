import { memo } from 'react';
import Box from '@mui/material/Box';
import { blue, deepPurple } from '@mui/material/colors';
import Boutique from 'boutique';
import BtnImg from 'components/Buttons/BtnImg';
import { CustomSvg } from 'util/svg';
import type { ChangePartFunctionV2 } from 'interfaces/app';
import type { KeyPart } from 'interfaces/padoru';

interface Props {
  uuid?: string;
  keyPart: KeyPart;
  show: boolean;
  onChangePart: ChangePartFunctionV2;
  isShort: boolean;
}

function SelectorSectionMobile(props: Props) {
  const { show, uuid, keyPart, onChangePart, isShort } = props;
  const defaultColors = [blue[300], blue[600], deepPurple[200], deepPurple[400]];

  return (
    <Box
      id={`grid_wrapper_${keyPart}`}
      sx={{
        display: show ? 'grid' : 'none',
        gridTemplateRows: isShort ? '11vh' : 'repeat(2, 11vh)',
        gridAutoFlow: 'column',
        gap: 1,
        backgroundColor: 'white',
        overflowX: 'auto',
        py: 0.5,
      }}
    >
      {Boutique.getArray(keyPart, '2').map(data => (
        <BtnImg
          key={data.uuid}
          uuid={data.uuid}
          size={48}
          selected={data.uuid === uuid}
          isMobile
          onClick={onChangePart(keyPart)}
          svgUri={new CustomSvg(data, defaultColors, true).dataUrl()}
        />
      ))}
    </Box>
  );
}

const SelectorSectionMobileMemo = memo(SelectorSectionMobile, (prev, next) => {
  const sameTab = prev.show === next.show;
  const samePiece = prev.uuid === next.uuid;
  const render = !sameTab || (sameTab && !samePiece);
  //const render = !samePiece;
  return !render;
});

/**
 * return true = no render
 * return false = render
 */

export default SelectorSectionMobileMemo;
//export default SelectorSection;
