import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import btn3dYellow from 'assets/imgs/btn_yellow_3d.png';
import promoBox from 'assets/imgs/promo_box.png';
import promoCombinar from 'assets/imgs/promo_combinar.png';
import promoFree from 'assets/imgs/promo_free.png';
import promoRgb from 'assets/imgs/promo_rgb.png';
import promoShare from 'assets/imgs/promo_share.png';
import { MAKER, MAKER_V2 } from 'paths';
import { checkNews } from 'util/helpers';
import Modal from 'components/Modals';
import PromoCard from './PromoCard';
// imgs
import logo from 'assets/icons/logo_full.svg';

const CARDS = [
  {
    title: 'pages.home.cards.box.title',
    text: 'pages.home.cards.box.text',
    img: promoBox,
  },
  {
    title: 'pages.home.cards.combinar.title',
    text: 'pages.home.cards.combinar.text',
    img: promoCombinar,
  },
  {
    title: 'pages.home.cards.rgb.title',
    text: 'pages.home.cards.rgb.text',
    img: promoRgb,
  },
  {
    title: 'pages.home.cards.free.title',
    text: 'pages.home.cards.free.text',
    img: promoFree,
  },
  {
    title: 'pages.home.cards.share.title',
    text: 'pages.home.cards.share.text',
    img: promoShare,
  },
];

const DISABLE_NEWS = true;
export function HomeStyled() {
  const { t } = useTranslation();
  const [openModalNews, setOpenModalNews] = useState<boolean>(!checkNews('cm_news_v2'));
  const closeNews = () => {
    checkNews('cm_news_v2', true);
    setOpenModalNews(false);
  };

  return (
    <Box>
      <Box id="news_wrapper">
        <Modal open={!DISABLE_NEWS && openModalNews} btnClose onClose={closeNews}>
          <Box>
            <Typography variant="h5">Padoru News!</Typography>
            <Typography>
              Don't miss the new version of Padoru maker. Sunday, October 20th, it is
              released to the public!
            </Typography>
            <Typography> - More styles.</Typography>
            <Typography> - New functions and style revision.</Typography>
            <Typography> - Gradient mode!</Typography>
            <Typography> - Always 100% free!!</Typography>
            <Box textAlign="right">
              <Button variant="contained" color="success" onClick={closeNews}>
                OK
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Box sx={{ color: 'white' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} columns={6}>
            <Grid item xs={6} sm={5} md={4}>
              <Box component="img" src={logo} mb={4} width={1} />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 3, sm: 5 }} columns={6}>
            {CARDS.map(({ title, text, img }) => (
              <Grid item xs={6} sm={3} md={2} key={title}>
                <PromoCard title={t(title)} text={t(text)} image={img} />
              </Grid>
            ))}
            <Grid item xs={6} sm={3} md={2}>
              <Box id="btn_start_wrapper" width={1} my={{ xs: 6, sm: '50%' }}>
                <Button variant="text" color="warning" sx={{ width: 1 }} disableRipple>
                  <Link
                    href={MAKER_V2}
                    component="a"
                    underline="none"
                    sx={{
                      p: 2,
                      width: 1,
                      color: 'black',
                      backgroundImage: `url(${btn3dYellow})`,
                      fontFamily: '"Kalam", cursive',
                      backgroundSize: '100% 300%',
                      backgroundPosition: 'top',
                      fontSize: '1.4rem',
                      letterSpacing: 1,
                      fontWeight: 'bold',
                      '&:hover': { backgroundPosition: 'center' },
                      '&:active': { backgroundPosition: 'bottom' },
                    }}
                  >
                    {t('pages.home.button_start')}
                  </Link>
                </Button>
                <Box width={1} textAlign="center">
                  <Link href={MAKER} sx={{ color: 'white' }}>
                    show version 1
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
