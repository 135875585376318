import { memo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import BtnBlur from './BtnBlur';
import BtnDirection from './BtnDirection';
import BtnTier from './BtnTier';
import type { GradiantMode } from 'interfaces/svg';

interface Props {
  colors: string[];
  gradiant: GradiantMode;
  onChangeGradiant: (gradiant: GradiantMode) => void;
  disabled: boolean;
  orientation?: 'horizontal' | 'vertical';
  floating?: boolean;
}

function BotoneraToolsV2(props: Readonly<Props>) {
  const {
    onChangeGradiant,
    gradiant,
    colors,
    disabled,
    orientation = 'vertical',
    floating = false,
  } = props;

  return (
    <Box id="botonera_tools_wrapper">
      <Stack
        direction={orientation === 'vertical' ? 'column' : 'row'}
        spacing={1}
        id="stack_btns_setters"
      >
        <BtnDirection
          onChangeGradiant={onChangeGradiant}
          gradiant={gradiant}
          disabled={disabled}
          floating={floating}
        />

        <BtnTier
          onChangeGradiant={onChangeGradiant}
          gradiant={gradiant}
          colors={colors}
          disabled={disabled}
          warningMode={gradiant.mode === 'l'}
          floating={floating}
        />

        <BtnBlur
          onChangeGradiant={onChangeGradiant}
          gradiant={gradiant}
          disabled={disabled}
          warningMode={gradiant.mode === 'l'}
          floating={floating}
        />
      </Stack>
    </Box>
  );
}

const BotoneraToolsV2Memo = memo(BotoneraToolsV2, (prev, next) => {
  const sameGrad = JSON.stringify(prev.gradiant) === JSON.stringify(next.gradiant);
  const sameColors = JSON.stringify(prev.colors) === JSON.stringify(next.colors);
  const sameOrien = prev.orientation === next.orientation;
  const shouldRender = !sameGrad || !sameColors || !sameOrien;
  return !shouldRender;
});

export default BotoneraToolsV2Memo;
