import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GradientIcon from '@mui/icons-material/Gradient';
import NoneTwoColorsIcon from '@mui/icons-material/InvertColorsOffRounded';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { contrastColor } from 'util/helpers';
import IconText from 'components/Labels/IconText';
import type { GradiantMode } from 'interfaces/svg';

interface Props {
  onChangeGradiant: (gradiant: GradiantMode) => void;
  gradiant: GradiantMode;
  colors: string[];
  disabled: boolean;
  floating: boolean;
  warningMode: boolean;
}

// nivelador, combinador, equilibrador, balanceador, balance de colores

export default function BotoneraToolsV2(props: Readonly<Props>) {
  const { t } = useTranslation();
  const { onChangeGradiant, gradiant, colors, disabled, floating, warningMode } = props;

  const [sectionTier, setSectionTier] = useState<number[]>([25, 85]);
  const minDistance = 55;
  const minValueSlider = 0;
  const maxValueSlider = 100;
  const stepper = 5;

  useEffect(() => {
    if (gradiant) {
      setSectionTier(calculateSectionTier(gradiant.tier));
    }
  }, []);

  const calculateSectionTier = (tier: number): number[] => {
    const nv1 = ((tier + stepper * 2) / 4 - maxValueSlider) * -1;
    const nv0 = nv1 - minDistance + stepper;
    return [nv0, nv1];
  };

  const [anchorElGradiant, setAnchorElGradiant] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleClickGradiant = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElGradiant(event.currentTarget);
  };

  const handleCloseGradiant = () => {
    setAnchorElGradiant(null);
  };

  const colorMajor = colors[Number(gradiant.tier > 100)];
  const css = useSliderStyles({ colors, border: contrastColor(colorMajor) });

  return (
    <Box id="btn_perccent_wrapper">
      <IconButton onClick={handleClickGradiant} disabled={disabled}>
        <GradientIcon fontSize="large" sx={{ transform: 'rotate(180deg)' }} />
      </IconButton>

      <Box id="popober_gradiant">
        <Popover
          open={!!anchorElGradiant}
          anchorEl={anchorElGradiant}
          anchorReference={floating ? 'anchorPosition' : 'anchorEl'}
          anchorPosition={{
            top: document.documentElement.clientHeight / 2,
            left: document.documentElement.clientWidth / 10,
          }}
          onClose={handleCloseGradiant}
          transitionDuration={100}
          id="popover_diretion"
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          sx={{ ml: 1.5 }}
          PaperProps={{ sx: { backgroundColor: grey[100] } }}
        >
          <Stack
            id="slider_gradiant_wrapper"
            spacing={2}
            direction="column"
            sx={{ p: 1.5, m: 0.5, minHeight: '12em', minWidth: '10em' }}
            alignItems="center"
          >
            {warningMode && (
              <Alert severity="warning" variant="outlined">
                <IconText
                  icon={<NoneTwoColorsIcon />}
                  text={t('components.tools.tier.two_colors')}
                  variant="caption"
                />
              </Alert>
            )}
            <Slider
              valueLabelDisplay="off"
              orientation="vertical"
              min={minValueSlider}
              max={maxValueSlider}
              classes={css}
              disabled={disabled}
              disableSwap
              track="inverted"
              value={sectionTier}
              onChange={(_e: Event, newValue: number | number[], activeThumb: number) => {
                if (!Array.isArray(newValue)) {
                  return;
                }

                let nV = [newValue[0], newValue[1]];

                if (activeThumb === 0) {
                  const clamped = Math.min(newValue[0], maxValueSlider - minDistance);
                  nV = [clamped, clamped + minDistance - stepper];
                  setSectionTier(nV);
                } else {
                  const clamped = Math.max(newValue[1], minDistance);
                  nV = [clamped - minDistance, clamped - stepper];
                  setSectionTier(nV);
                }

                // minD 55, step 5, total 100

                // arriba = 10
                //  1  [45, 95]
                //  0  [45, 95]

                // abajo = 190
                // 1 [0, 50]
                // 0 [0, 50]

                //if (nV[0] + minDistance >= 100) {
                //  tier = nV[0] + minDistance - (stepper * 2)
                //} else {
                //  tier = minDistance - nV[1] + stepper
                //}

                const tier = (maxValueSlider - nV[1]) * 4 - stepper * 2;

                // console.log('seteando final scale tier', activeThumb, nV, tier);

                //if (tier > 190) tier = 190;
                //if (tier < 10) tier = 10;

                onChangeGradiant({
                  blur: gradiant.blur,
                  tier: tier,
                  mode: gradiant.mode,
                });
              }}
            />
          </Stack>
        </Popover>
      </Box>
    </Box>
  );
}

interface SliderStylesParams {
  colors: string[];
  border: string;
}
const useSliderStyles = makeStyles({
  root: {
    minWidth: '10em',
    minHeight: 'inherit',
    width: '90%',
  },
  rail: ({ colors }: SliderStylesParams) => ({
    backgroundImage: `linear-gradient(180deg, ${colors[0]} 45%, ${colors[1]} 55%)`,
    width: '100%',
  }),
  track: ({ border }: SliderStylesParams) => ({
    backgroundColor: 'transparent',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'block',
    width: '95%',
    border: `5px solid ${border}`,
  }),
  thumb: {
    display: 'none',
  },
});
