import type { JSvg } from 'interfaces/svg';
import type { KeyPart, PadoruVersion } from 'interfaces/padoru';

interface NonNullableMap<K, V> extends Map<K, V> { get(key: K): V; }
type IBoutiquePart = NonNullableMap<string, JSvg>;
type IBoutique = NonNullableMap<string, IBoutiquePart>;

const Boutique: IBoutique = new Map();

function importAll(r: __WebpackModuleApi.RequireContext) {
  r.keys()
    .filter((name: string) => !/index/gi.test(name) && /(.*)(.ts|.js)/.test(name))
    .forEach((m: string) => {
      const prop = m.slice(2, -3);
      const { data } = r(m);
      const boutiquePart = new Map(data.map((jsvg: any) => [jsvg.uuid, jsvg]));
      Boutique.set(prop as KeyPart, boutiquePart as IBoutiquePart);
    });
}
importAll(require.context(__dirname, false, /(.*)/));

const exported: {
  get: (key: KeyPart, v?: PadoruVersion) => IBoutiquePart;
  getArray: (key: KeyPart, v?: PadoruVersion) => JSvg[];
} = {
  get: (key: KeyPart, v?: PadoruVersion) => Boutique.get(`${key}_${v}`) ?? Boutique.get(key),
  getArray: (key: KeyPart, v?: PadoruVersion) => Array.from(
    (Boutique.get(`${key}_${v}`) ?? Boutique.get(key)).values()
  ),
}

export default exported;
