import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import ToggleButton from '@mui/material/ToggleButton';
import { blue } from '@mui/material/colors';
import { isRandomFeature } from 'util/padoru-api';
import svg2png from 'util/svg2png';

interface Props {
  svgUri: string;
  onClick: (uuid: string) => void;
  isMobile: boolean;
  uuid: string;
  selected: boolean;
  size?: number | string;
}

function BtnImg(props: Props) {
  const { svgUri, onClick, uuid, isMobile, selected, size = 60 } = props;
  const [src, setSrc] = useState<string>('');

  const isRandom = isRandomFeature();
  useEffect(() => {
    if (!isMobile && !isRandom && !!svgUri) {
      svg2png(svgUri).then(setSrc);
    }
  }, [isMobile, isRandom, svgUri]);

  useEffect(() => {
    setSrc(svgUri);
  }, [svgUri]);

  return (
    <ToggleButton
      value={uuid}
      onClick={() => onClick(uuid)}
      sx={{ p: 0, border: 'none' }}
    >
      <Avatar
        sx={{
          bgcolor: blue[100],
          p: 1,
          width: size,
          height: size,
          borderStyle: selected ? 'solid' : 'none',
          borderColor: blue[800],
          borderWidth: 2,
        }}
        src={src}
        variant="rounded"
      />
    </ToggleButton>
  );
}

export default BtnImg;
