import React, { useState } from 'react';
import { alpha, colors } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import is_mobile from 'is-mobile';
import Padoru from 'util/padoru';
import Botonera from 'components/Botonera';
import FormV2 from 'components/Form/FormV2';
import StorageBar from 'components/ToolBar/StorageBar';
import ToolBarV2 from 'components/ToolBar/ToolBarV2';
import WorkArea from 'components/WorkArea';
import type {
  ChangeColorFunctionV2,
  ChangeGradiantFunctionV2,
  ChangePartFunctionV2,
} from 'interfaces/app';
import type { KeyPart } from 'interfaces/padoru';

interface Props {
  onChangeName: (n: string) => void;
  onChangeSkin: (color: string) => void;
  onChangePart: ChangePartFunctionV2;
  onChangeColor: ChangeColorFunctionV2;
  onChangeGradiant: ChangeGradiantFunctionV2;
  onRestorePadoru: (p: Padoru) => void;
  isFirstVisit: boolean;
  openTuto: boolean;
  openTutorial: (o: boolean) => void;
  padoru: Padoru;
  padoruName: string;
}

export default function Maker2Web(props: Props) {
  const isSm = useMediaQuery('(max-width:600px)');
  const isMd = useMediaQuery('(max-width:900px)');
  const isMobile = is_mobile({ tablet: true });

  const {
    onChangeName,
    onChangeSkin,
    onChangePart,
    onChangeColor,
    onChangeGradiant,
    onRestorePadoru,
    openTutorial,
    padoru,
    padoruName,
  } = props;

  const [partTab, setPartTab] = useState<KeyPart>('hair_middle');

  return (
    <React.Fragment>
      <div id="modal-mode-web">
        <StorageBar v="2" isMobile={isMobile} onRestorePadoru={onRestorePadoru} />
      </div>

      <Container
        id="maker_2_main"
        component="main"
        maxWidth="xl"
        sx={{ overflow: 'auto', flex: 1, height: 'auto' }}
      >
        <Grid
          container
          id="maker_2_grid_container"
          justifyContent="space-around"
          spacing={2}
          sx={{
            mt: 3,
            mb: '2em',
            backgroundColor: alpha(colors.indigo[900], 0.3),
            py: 3,
          }}
        >
          <Grid id="workarea_grid" item xs={12} md={4}>
            <WorkArea
              v="2"
              padoru={padoru}
              padoruName={padoruName}
              onChangeSkin={onChangeSkin}
              onChangeName={onChangeName}
              isMobile={isMobile}
              isMd={isMd}
            />
          </Grid>

          <Grid item xs={12} md={8} pr={5.5} id="derecha_grid" position="relative">
            <FormV2
              partTab={partTab}
              padoru={padoru}
              handlerPartTab={setPartTab}
              onChangePart={onChangePart}
              isMobile={isMd}
              isSm={isSm}
            />

            <Botonera
              partTab={partTab}
              orientation={isSm ? 'horizontal' : 'vertical'}
              onChangeColor={onChangeColor}
              onChangeGradiant={onChangeGradiant}
              colors={padoru.parts.get(partTab)?.colors ?? []}
              gradiant={padoru.parts.get(partTab)?.gradiant}
            />
          </Grid>
        </Grid>
      </Container>

      <ToolBarV2
        isMobile={isMobile}
        isMd={isMd}
        onNewPadoru={onRestorePadoru}
        openTutorial={openTutorial}
      />
    </React.Fragment>
  );
}
