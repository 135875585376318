import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Typography, { type TypographyProps } from '@mui/material/Typography';

interface Props {
  id?: string;
  text: string;
  icon: JSX.Element;
  variant?: TypographyProps['variant'];
  sx?: SxProps;
}

export default function IconText(props: Props) {
  const { id, icon, text, variant = 'body1', sx = {} } = props;

  const iconSx = {
    ...(icon.props.sx && { ...icon.props.sx }),
    verticalAlign: 'middle',
  };

  return (
    <Typography id={id} sx={sx} variant={variant}>
      <SvgIcon sx={iconSx}>{icon}</SvgIcon>
      <Box sx={{ verticalAlign: 'middle' }} component="span" ml={0.5}>
        {text}
      </Box>
    </Typography>
  );
}
